import { get } from "lodash";

export const getIsError = (formik, fieldName) =>
  get(formik.touched, fieldName, "") &&
  Boolean(get(formik.errors, fieldName, ""));

export const getErrorMessage = (formik, fieldName) =>
  get(formik.touched, fieldName, "")
    ? get(formik.errors, fieldName, "") || ""
    : "";
