import { Button, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import premiumSellerBg from "../../assets/images/premiumSellerBg.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default function PremiumSellerPricing() {
  const classes = useStyles();
  return (
    <div className="relative px-4 py-[100px] mt-[100px] md:px-0 bg-gray-f8">
      <Container classes={{ root: classes.container }}>
        <div className="flex flex-col items-center">
          <img
            src={premiumSellerBg}
            alt="premiumSellerBg"
            className="absolute top-0 bottom-0 left-0 right-0 object-cover h-full w-full"
          />
          <h2 className="text-center text-3xl text-primary-main font-medium">
            Become a premium Seller
          </h2>
          <p className="text-center mt-1 text-lg text-primary-light">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus{" "}
          </p>
          <h2 className="text-center mt-6 text-5xl text-primary-main font-medium">
            40 USDT
          </h2>
          <p className="text-center mt-1 text-base text-primary-light">
            Condition ExChange
          </p>
          <p className="text-sm mt-12 mb-2 text-center text-primary-semiLight">
            See, One price. Simple.
          </p>
          <Button variant="contained" className="w-[189px]">
            Perchase Now
          </Button>
        </div>
      </Container>
    </div>
  );
}
