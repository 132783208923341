import React from "react";
import hexagonBg from "../../assets/icons/hexagon.svg";

export default function HexagonBadge({ number }) {
  return (
    <div className="w-[50px] h-[50px] relative flex items-center justify-center">
      <img alt="hexagonBg" src={hexagonBg} className="absolute" />
      <p className="text-lg font-medium text-white text-center z-10">
        {number}
      </p>
    </div>
  );
}
