import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productImageUrl: "",
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setProductImageUrl(state, action) {
      state.productImageUrl = action.payload;
    },
  },
});

export const chatReducer = chatSlice.reducer;

export const selectChatProductImageUrl = (state) => {
  return state.chat.productImageUrl;
};
