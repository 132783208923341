import React from "react";

export default function ProductEmpty() {
  return (
    <div className=" justify-start flex flex-1 ">
      <h2 className="text-center text-2xl text-primary-main font-medium">
        Not found product.
      </h2>
    </div>
  );
}
