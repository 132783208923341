import { Button } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import btc from "../../assets/icons/btc.svg";
import eth from "../../assets/icons/eth.svg";
import verify from "../../assets/icons/verify.svg";
import zcoin from "../../assets/icons/zcoin.svg";
import defaultImage from "../../assets/images/defaultImage.svg";
import { ALERT_TYPE } from "../../libs/context/AlertContext";
import { getOrCreateRoomBetweenUsers } from "../../libs/Firebase";
import useAlert from "../../libs/hooks/useAlert";
import { getImageUrl } from "../../libs/utils/utils";
import { selectAccessToken, selectUID } from "../../redux/modules/authSlice";
import { chatSlice } from "../../redux/modules/chatSlice";

const coinIcons = {
  BTC: btc,
  ETH: eth,
  ZCOIN: zcoin,
};

function ProductCardSkeleton() {
  return (
    <div className="p-4 min-w-[287px] h-[632px] ">
      <Skeleton
        animation={"wave"}
        variant="rectangular"
        width={"100%"}
        height={200}
      />
      <div className="h-1" />
      <Skeleton animation={"wave"} variant="text" />
      <Skeleton animation={"wave"} variant="text" width="45%" />
      <div className="h-1" />
      <Skeleton animation={"wave"} variant="text" />
      <Skeleton animation={"wave"} variant="text" width="85%" />
      <div className="h-2" />
      <Skeleton animation={"wave"} variant="text" width="45%" height={30} />
      <div className="h-2" />
      <div className="flex flex-row space-x-1">
        <Skeleton
          animation={"wave"}
          variant="circular"
          width={40}
          height={40}
        />
        <Skeleton
          animation={"wave"}
          variant="circular"
          width={40}
          height={40}
        />
        <Skeleton
          animation={"wave"}
          variant="circular"
          width={40}
          height={40}
        />
      </div>
      <div className="h-2" />
      <Skeleton animation={"wave"} variant="text" width="45%" />
      <div className="h-8" />
      <Skeleton
        animation={"wave"}
        variant="rectangular"
        width={"100%"}
        height={48}
      />
      <div className="h-1" />
      <Skeleton
        animation={"wave"}
        variant="rectangular"
        width={"100%"}
        height={48}
      />
    </div>
  );
}

export default function ProductCard({
  isLoading = false,
  productId = null, // number
  accountCode = "",
  topic = "",
  productName = "",
  productDescription = "",
  productPrice = 49.33,
  unit = "USDT",
  supportCoin = ["BTC", "ETH", "ZCOIN"],
  files = {},
  accountVerify = null,
  account = {},
  onClickChatNow = () => {},
  onClickDetail = () => {},
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert, hideAlert } = useAlert();
  const currentUserId = useSelector(selectUID);
  const accessToken = useSelector(selectAccessToken);
  // const targetUserRefId = "oet9LNZSqN"; // accountCode
  const targetUserRefId = accountCode;

  const productUrl = getImageUrl(files && files[0]);
  const seller = account.firstname
    ? `${account.firstname} ${account.lastname}`
    : "";

  const handleClickChatNow = async () => {
    if (!accessToken) {
      showAlert({
        type: ALERT_TYPE.WARNING,
        title: "Your never login.",
        description:
          "Please back to login or register for continue chat with dealer.",
        button: [{ title: "Close", onClick: hideAlert }],
      });
      return;
    }

    if (currentUserId === targetUserRefId) {
      return navigate(`/chat`);
    }

    onClickChatNow();
    const roomId = await getOrCreateRoomBetweenUsers(
      targetUserRefId,
      currentUserId
    );
    if (productUrl) {
      dispatch(chatSlice.actions.setProductImageUrl(productUrl));
    }
    navigate(`/chat/room/${roomId}`);
  };

  if (isLoading) return <ProductCardSkeleton />;

  return (
    <div className="p-4 min-w-[287px]">
      <img
        src={productUrl || defaultImage}
        alt="product"
        // w-[255px]
        className={`w-full h-[200px] overflow-hidden ${
          productUrl ? "object-cover" : "object-contain"
        }  bg-gray-50`}
      />
      <h5 className="mt-5 text-xl font-medium text-primary-main">
        {productName}
      </h5>
      <p className="mt-4 text-base text-primary-light">{productDescription}</p>
      <h2 className="mt-6 text-2xl font-bold text-primary-main">{`${
        productPrice || 0
      } ${unit}`}</h2>
      <div className="flex flex-row space-x-1 mt-6">
        {supportCoin.map((coin) => (
          <img key={coin} src={coinIcons[coin]} alt={coin} />
        ))}
      </div>
      <div className="mt-6 flex flex-row">
        <img src={verify} alt="verify" />
        <div className="ml-1">
          <p className="text-sm text-primary-semiLight">
            {accountVerify ? "Verificate Seller" : "Not Verificate"}
          </p>
          <p className="text-base font-medium text-primary-main">{seller}</p>
        </div>
      </div>
      <div className="mt-6" />
      <Button
        variant="contained"
        color="primary"
        size="large"
        className="w-full h-[48px]"
        onClick={handleClickChatNow}
      >
        Chat Now!
      </Button>
      <div className="mt-2" />
      <Button
        variant="outlined"
        color="primary"
        size="large"
        className="w-full h-[48px]"
        onClick={onClickDetail}
        LinkComponent={Link}
        to={`/product/${productId}`}
      >
        Detail
      </Button>
    </div>
  );
}
