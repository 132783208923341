import Button from "@mui/material/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import arrowRight from "../../assets/icons/arrowRight.svg";
import { userOffline } from "../../libs/Firebase";
import useModalLoading from "../../libs/hooks/useModalLoading";
import {
  authSlice,
  selectAccessToken,
  selectUID,
} from "../../redux/modules/authSlice";
import { uiSlice } from "../../redux/modules/uiSlice";
import { useLogoutMutation } from "../../services/auth";
import { useMyProfileQuery } from "../../services/profile";

export default function NavbarMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const asscessToken = useSelector(selectAccessToken);
  const userId = useSelector(selectUID);
  const [logout] = useLogoutMutation();
  const { showLoading, hideLoading } = useModalLoading();
  const { data: profile } = useMyProfileQuery(asscessToken, {
    refetchOnMountOrArgChange: true,
  });

  const handleJoinUs = () => {
    dispatch(uiSlice.actions.showModalRegister());
  };

  const handleLogout = async () => {
    showLoading();
    try {
      await logout();
      hideLoading();
      userOffline(userId);
      dispatch(authSlice.actions.logout());
      navigate("/");
      // await firebaseLogout();
    } catch (error) {
      hideLoading();
    }
  };

  return (
    <>
      <div className="md:hidden w-full flex justify-end">
        {asscessToken ? (
          <Button color="primary" onClick={handleLogout}>
            Logout
            <img src={arrowRight} alt="arrowRight" className="ml-2" />
          </Button>
        ) : (
          <Button color="primary" onClick={handleJoinUs}>
            Join Us
            <img src={arrowRight} alt="arrowRight" className="ml-2" />
          </Button>
        )}
      </div>
      <div className="hidden md:block">
        <div className="flex items-center justify-between space-x-10">
          <Button color="primary" to="/" component={Link}>
            Home
          </Button>
          <Button color="primary" to="/market" component={Link}>
            Marketplace
          </Button>
          <Button color="primary" to="/chat" component={Link}>
            Contact
          </Button>
          {asscessToken && profile && (
            <Button color="primary" to="/my-profile" component={Link}>
              My Profile
            </Button>
          )}
        </div>
      </div>
      <div className="hidden md:block">
        {asscessToken ? (
          <Button
            variant="contained"
            style={{ width: 150 }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{ width: 150 }}
            onClick={handleJoinUs}
          >
            Join Us
          </Button>
        )}
      </div>
    </>
  );
}
