import { Button } from "@mui/material";
import React from "react";

export default function BannerRectangle({ title, description, image }) {
  return (
    <div
      style={{
        height: 250,
        overflow: "visible",
      }}
      className="flex flex-1 flex-col md:flex-row w-full md:bg-primary-main rounded relative "
    >
      <div className="hidden md:block h-[294px]">
        <div className="md:w-[368px]" />
        <div className="h-[294px] overflow-hidden absolute -mt-[44px]">
          <img src={image} alt="bannder-rectangle" className="w-[368px]" />
        </div>
      </div>
      <div className="relative">
        <div className="bg-primary-main rounded h-[206px]" />
        <div className="absolute top-0 -mt-[44px] overflow-hidden h-[250px]">
          <img src={image} alt="bannder-rectangle" className="h-[294px]" />
        </div>
      </div>
      <div className="mt-[43px] md:mt-0 md:ml-[60px] md:mr-10 max-w-[337px] md:self-center">
        <p className="text-lg font-medium text-secondary-main ">{title}</p>
        <p className="text-2xl font-medium text-primary-main md:text-white mt-4 mb-4 w-min-content">
          {description}
        </p>
        <Button
          className="h-[48px] w-[150px]"
          variant="contained"
          color="secondary"
        >
          Chat now!!
        </Button>
      </div>
    </div>
  );
}
