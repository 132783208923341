import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isModalRegisterVisible: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showModalRegister(state, action) {
      state.isModalRegisterVisible = true;
    },
    hideModalRegister(state, action) {
      state.isModalRegisterVisible = false;
    },
  },
});

export const uiReducer = uiSlice.reducer;

export const selectIsModalRegisterVisible = (state) => {
  return state.ui.isModalRegisterVisible;
};
