import { Container } from "@mui/material";
import qs from "qs";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AdsBanner from "../components/landing/AdsBanner";
import Layout from "../components/layout/Layout";
import AllProduct from "../components/product/AllProduct";
import NewArrivalItem from "../components/product/NewArrivalItem";
import PremiumSellerPricing from "../components/shared/PremiumSellerPricing";
import SearchBarProduct from "../components/shared/SearchBarProduct";

export default function Marketplace() {
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOnSubmitSearch = ({ categoryId, searchText }) => {
    const params = qs.stringify({
      ...(categoryId && { categoryId }),
      ...(searchText && { searchText }),
    });
    navigate(`/product/search?${params}`);
  };

  return (
    <Layout isDisabledContainer>
      <Container>
        <h1 className="text-3xl md:text-5xl mt-[45px] font-roboto font-medium text-primary-main">
          Marketplace
        </h1>
        <SearchBarProduct onSubmit={handleOnSubmitSearch} />
        <NewArrivalItem />
        <AdsBanner />
        <AllProduct />
      </Container>
      <PremiumSellerPricing />
    </Layout>
  );
}
