import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import React, { createContext, useState } from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
}));

export const ModalLoadingContext = createContext({
  isOpen: false,
  showLoading: () => {},
  hideLoading: () => {},
});

function ModalLoadingProvider({ children }) {
  const classes = useStyles();

  const [isOpen, setOpen] = useState(false);
  const showLoading = () => {
    setOpen(true);
  };
  const hideLoading = () => {
    setOpen(false);
  };

  return (
    <ModalLoadingContext.Provider
      value={{
        isOpen,
        showLoading,
        hideLoading,
      }}
    >
      {children}
      <Backdrop className={classes.backdrop} open={isOpen} id="loading">
        <CircularProgress color="inherit" />
      </Backdrop>
    </ModalLoadingContext.Provider>
  );
}

export default ModalLoadingProvider;
