import { Button, Container, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkin from "../../assets/icons/linkin.svg";
import twitter from "../../assets/icons/twitter.svg";
import youtube from "../../assets/icons/youtube.svg";
import logoWhite from "../../assets/images/logoWhite.svg";
import { selectAccessToken } from "../../redux/modules/authSlice";
import { uiSlice } from "../../redux/modules/uiSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    // height: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));
// h-[209px] md:-[147px]
export default function Footer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const asscessToken = useSelector(selectAccessToken);

  const handleJoinUs = () => dispatch(uiSlice.actions.showModalRegister());

  return (
    <footer className="bg-primary-main h-[313px] md:h-[254px] px-0 ">
      <Container
        classes={{ root: classes.container }}
        style={{ display: "flex", height: "100%" }}
      >
        <div className="flex h-full flex-1 flex-col md:flex-row justify-center items-center md:items-start md:justify-between md:pt-[40px]">
          <img src={logoWhite} alt="logo-white" />
          <div className="mt-10 md:mt-0">
            {!asscessToken && (
              <Button
                variant="outlined"
                className="w-[150px]"
                color="onPrimary"
                onClick={handleJoinUs}
              >
                Join Us
              </Button>
            )}
          </div>
        </div>
        <hr className="bg-white" />
        <div className="h-[104px] md:[107px] flex justify-center md:justify-between">
          <div className="hidden md:flex items-center justify-between space-x-10">
            <Button color="onPrimary" to="/" component={Link}>
              Home
            </Button>
            <Button color="onPrimary" to="/market" component={Link}>
              Marketplace
            </Button>
            <Button color="onPrimary" to="/contact" component={Link}>
              Contact
            </Button>
          </div>
          <div className="flex items-end pb-[32px] space-x-6">
            <IconButton>
              <img src={instagram} alt="instagram" />
            </IconButton>
            <IconButton>
              <img src={youtube} alt="youtube" />
            </IconButton>
            <IconButton>
              <img src={twitter} alt="twitter" />
            </IconButton>
            <IconButton>
              <img src={linkin} alt="linkin" />
            </IconButton>
            <IconButton>
              <img src={facebook} alt="facebook" />
            </IconButton>
          </div>
        </div>
      </Container>
    </footer>
  );
}
