import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import btcIcon from "../../assets/icons/btc-home.svg";
import ethIcon from "../../assets/icons/eth-home.svg";
import crytodeeMarkIcon from "../../assets/images/crytodee-mark.svg";
import phoneLandingPage from "../../assets/images/phoneLandingPage.svg";
import { selectAccessToken } from "../../redux/modules/authSlice";
import { uiSlice } from "../../redux/modules/uiSlice";

export default function BannerHome() {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken);

  const handleJoinUs = () => dispatch(uiSlice.actions.showModalRegister());

  return (
    <div className="pt-8 pb-20 flex">
      <div className="flex flex-1 ">
        <div className="flex-col md:max-w-[539px]">
          <h1 className="text-3xl md:text-5xl font-roboto font-medium text-primary-main">
            Introduce <span className="text-secondary-main">CryptDee</span>
            <br />
            Quickly & Effectively
          </h1>
          <p className="text-sm text-primary-light mt-4">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus
          </p>
          <p className="text-sm text-primary-light mt-4">
            mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
            sem. Nulla consequat massa quis enim.
          </p>
          <img
            src={btcIcon}
            alt="btc-icon"
            className="md:hidden z-0 absolute top-1/3 right-0 transform -translate-y-1/2"
          />
          <img
            src={ethIcon}
            alt="eth-icon"
            className="md:hidden z-0 absolute  ml-[-24px] mt-[-24px]"
          />
          <div className="flex flex-row space-x-5 mt-8 ">
            <Button
              className="h-[48px] w-full md:max-w-[150px] z-10"
              variant="contained"
              to="/market"
              component={Link}
            >
              <span className="text-base">Marketplace!!</span>
            </Button>
            {!accessToken && (
              <Button
                className="h-[48px] w-full md:max-w-[150px] z-10"
                variant="outlined"
                onClick={handleJoinUs}
              >
                <span className="text-base">Join Us</span>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="hidden md:flex flex-1  justify-end">
        <img
          src={phoneLandingPage}
          alt="phone-lading-icon"
          className="w-[418px] ml-[-24px] mt-[-24px]"
        />
      </div>
      <img
        src={crytodeeMarkIcon}
        alt="crytodee-mark-icon"
        className="hidden md:block z-0 absolute left-0"
      />
    </div>
  );
}
