import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as CheckedRoundIcon } from "../../assets/icons/checked-round.svg";
import uploadIdCardBackIcon from "../../assets/images/upload-id-card-back.svg";
import uploadIdCardFrontIcon from "../../assets/images/upload-id-card-front.svg";

let a = uploadIdCardBackIcon;

function useFileUpload() {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return {
    files,
    getRootProps,
    getInputProps,
  };
}

export default function UploadDocument({
  title = "",
  description = "",
  id = "",
  hideBack = false,
  onChangeFile = () => {},
}) {
  const idCardFrontFile = useFileUpload();
  const idCardBackFile = useFileUpload();
  const [isExpand, setExpand] = useState(false);
  const hasValue =
    idCardFrontFile.files &&
    idCardFrontFile.files[0]?.preview &&
    (!hideBack
      ? idCardBackFile.files && idCardBackFile.files[0]?.preview
      : true);

  useEffect(() => {
    if (hasValue) {
      onChangeFile({
        front: idCardFrontFile,
        ...(!hideBack && { back: idCardBackFile }),
      });
    }
  }, [hasValue]);

  const handleClickBar = () => setExpand(!isExpand);

  const barBgColor = isExpand ? "bg-secondary-main" : "bg-primary-main";

  return (
    <div>
      <div
        className={`min-h-[72px] ${barBgColor} rounded mt-2 flex flex-row items-center justify-between px-8 cursor-pointer`}
        onClick={handleClickBar}
      >
        <p className="text-white">{title}</p>
        {hasValue && !isExpand ? (
          <CheckedRoundIcon color="#F2983D" />
        ) : (
          <CheckedRoundIcon color="rgba(255,255,255,0.5)" />
        )}
      </div>
      <div className={`h-[456px] ${isExpand ? "block" : "hidden"}`}>
        <p className="text-primary-light mt-6">{description}</p>

        <div
          {...idCardFrontFile.getRootProps({ className: "dropzone" })}
          className="flex flex-1 justify-center cursor-pointer  mt-1"
        >
          <input {...idCardFrontFile.getInputProps()} />
          <img
            alt="uploadIdCardFrontIcon"
            src={
              (idCardFrontFile.files && idCardFrontFile.files[0]?.preview) ||
              uploadIdCardFrontIcon
            }
            className="h-[150px]"
          />
        </div>
        {!hideBack && (
          <div
            {...idCardBackFile.getRootProps({ className: "dropzone" })}
            className="flex flex-1 justify-center cursor-pointer py-5 mt-6"
          >
            <input {...idCardBackFile.getInputProps()} />
            <img
              alt="uploadIdCardBackIcon"
              src={
                (idCardBackFile.files && idCardBackFile.files[0]?.preview) ||
                uploadIdCardBackIcon
              }
              className="h-[150px]"
            />
          </div>
        )}

        <p className="text-primary-semiLight text-center  mt-6">
          Upload .jpg, .jpeg or .png file and not exceeding 5 mb.
        </p>
      </div>
    </div>
  );
}
