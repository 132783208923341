import { Container } from "@mui/material";
import * as React from "react";
import Layout from "../components/layout/Layout";
import PremiumSellerPricing from "../components/shared/PremiumSellerPricing";
import VerfiyAccountForm from "../components/verifyAccount/VerfiyAccountForm";

export default function VerifyAccount() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout isDisabledContainer>
      <Container>
        <div className="my-[100px]">
          <VerfiyAccountForm />
        </div>
      </Container>
      <PremiumSellerPricing />
    </Layout>
  );
}
