import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useMemo, useState } from "react";
import timeProgress from "../../assets/icons/timeProgress.svg";
import crytodeeMarkIcon from "../../assets/images/crytodee-mark.svg";
import c1 from "../../assets/images/mocks/c1.svg";
import c2 from "../../assets/images/mocks/c2.svg";
import c3 from "../../assets/images/mocks/c3.svg";
import c4 from "../../assets/images/mocks/c4.svg";
import n1 from "../../assets/images/mocks/n1.svg";
import n2 from "../../assets/images/mocks/n2.svg";
import n3 from "../../assets/images/mocks/n3.svg";
import n4 from "../../assets/images/mocks/n4.svg";
import r1 from "../../assets/images/mocks/r1.svg";
import r2 from "../../assets/images/mocks/r2.svg";
import r3 from "../../assets/images/mocks/r3.svg";
import usePrevious from "../../libs/hooks/usePrevious";
import { getProductSkeletonItemLength } from "../../libs/utils/utils";
import { useCategoryListQuery } from "../../services/categories";
import { useProductListQuery } from "../../services/product";
import ProductCard from "./ProductCard";
import ProductEmpty from "./ProductEmpty";
const mockProduct = [
  { url: c1 },
  { url: c2 },
  { url: c3 },
  { url: c4 },
  { url: n1 },
  { url: n2 },
  { url: n3 },
  { url: n4 },
  { url: r1 },
  { url: r2 },
  { url: r3 },
];
export default function AllProduct({
  hideMark = false,
  mode = "full",
  categoryId = "",
  searchText = "",
}) {
  const isFullMode = mode === "full";
  const { data: categoryList, isLoading: catLoading } = useCategoryListQuery();
  const categories = useMemo(() => categoryList || [], [categoryList]);
  const [category, setCategory] = useState(null);
  const [page, setPage] = useState(1);
  const [dataProduct, setDataProduct] = useState([]);

  const { data, isLoading, isFetching, isSuccess } = useProductListQuery(
    {
      page: page,
      pageLimit: 10,
      categoryId: isFullMode ? category : categoryId,
      ...(isFullMode ? {} : { search: searchText }),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isFullMode ? !category : false,
    }
  );
  const productList =
    isLoading || isFetching
      ? getProductSkeletonItemLength(4, dataProduct)
      : dataProduct || [];

  useEffect(() => setCategory(categories[0]?.categoryId), [categories]);

  useEffect(() => {
    setDataProduct([]);
    setPage(1);
  }, [category, categoryId, searchText]);

  const previsFetching = usePrevious(isFetching);
  useEffect(() => {
    if (previsFetching !== isFetching && previsFetching && !isFetching) {
      setDataProduct([...dataProduct, ...(data || [])]);
    }
  }, [data, dataProduct, isFetching, previsFetching]);

  const hasNext = data && data.length !== 0;

  const handleCategoryChange = (event) => setCategory(event.target.value);
  const handleOnLoadMore = () => {
    if (data && data.length > 0) {
      setPage(page + 1);
    }
  };

  if (isSuccess && !productList.length) {
    return (
      <div className="mt-[44px]">
      <ProductEmpty />
    </div>
    )
  }

  return (
    <div className="mt-[44px]">
      {isFullMode && (
        <div className="flex flex-col md:flex-row">
          <h2 className="text-3xl font-medium text-primary-main">
            All Products
          </h2>
          <div className="mt-6 md:hidden">
            <FormControl className="w-full bg-white">
              <Select
                labelId="select-category-label"
                id="select-category"
                value={category}
                onChange={handleCategoryChange}
                displayEmpty
              >
                {categories.map((catItem) => (
                  <MenuItem key={catItem.categoryId} value={catItem.categoryId}>
                    {catItem.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="hidden md:flex flex-row ml-10">
            {categories.map((catItem) => (
              <MenuItem
                key={catItem.categoryId}
                onClick={() => {
                  setCategory(catItem.categoryId);
                }}
              >
                <p
                  className={`${
                    category === catItem.categoryId
                      ? "text-secondary-main"
                      : "text-primary-main"
                  } font-medium`}
                >
                  {catItem.categoryName}
                </p>
              </MenuItem>
            ))}
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-6">
        {productList.map((product, index) => (
          <ProductCard
            key={index}
            {...product}
            isLoading={isLoading || isFetching}
          />
        ))}
      </div>
      {!hideMark && (
        <img
          src={crytodeeMarkIcon}
          alt="crytodee-mark-icon"
          className="hidden md:block z-0 absolute left-0 -mt-[800px] -ml-[80px]"
        />
      )}
      <div className="w-full flex justify-center mt-[60px]">
        {hasNext && (
          <Button
            variant="contained"
            color="paperGray"
            className="w-[178px] h-[56px]"
            onClick={handleOnLoadMore}
          >
            <img src={timeProgress} alt="timeProgressIcon" />
            <span className="flex ml-[19px] text-base font-medium text-center">
              View more
            </span>
          </Button>
        )}
      </div>
    </div>
  );
}
