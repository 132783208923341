import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  rememberMe: false,
  accessToken: "",
  sessionToken: "",
  uid: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAccessToken(state, action) {
      state.rememberMe = action.payload.rememberMe;
      if (action.payload.rememberMe) {
        state.accessToken = action.payload.accessToken;
        state.sessionToken = "";
      } else {
        state.accessToken = "";
        state.sessionToken = action.payload.accessToken;
      }
    },
    updateUID(state, action) {
      state.uid = action.payload;
    },
    logout: () => initialState,
  },
});

export const authReducer = persistReducer(
  {
    key: "rtk:auth",
    storage,
    whitelist: ["accessToken", "rememberMe", "uid"],
  },
  authSlice.reducer
);

export const selectAccessToken = (state) => {
  if (state.auth.rememberMe && state.auth.accessToken) {
    return state.auth.accessToken;
  }
  return state.auth.sessionToken;
};

export const selectUID = (state) => {
  return state.auth.uid;
};
