import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import * as React from "react";

export const AntTabs = styled(Tabs)({
  borderBottom: "1px solid rgba(7, 35, 68, 0.18)",
});

export const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
    fontWeight: "500",
    color: "rgba(7, 35, 68, 0.6)",
    "&.Mui-selected": {
      color: "#F7931A",
    },
  })
);

const useStyles = makeStyles((theme) => ({
  tab: {
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 200px)",
    },
  },
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className="h-full"
      {...other}
    >
      {value === index && (
        <div
          className={`p-[34px] md:p-[65px] md:pt-[34px] overflow-y-scroll ${classes.tab}`}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
