import { IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../assets/icons/close.svg";
import logoImage from "../../assets/images/logo.svg";
import {
  selectIsModalRegisterVisible,
  uiSlice,
} from "../../redux/modules/uiSlice";
import LoginTab from "./LoginTab";
import RegisterTab from "./RegisterTab";
import { a11yProps, AntTab, AntTabs, TabPanel } from "./Tab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
};

export default function RegisterModal() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);
  const open = useSelector(selectIsModalRegisterVisible);
  const handleClose = () => dispatch(uiSlice.actions.hideModalRegister());

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  return (
    <div>
      <Modal
        keepMounted={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        style={{}}
        BackdropProps={{
          style: {
            background: "rgba(7, 35, 68, 0.31)",
            backdropFilter: "blur(20px)",
          },
        }}
      >
        <div className="block sm:flex justify-center items-center w-full md:w-auto h-full p-2">
          <div className={` md:w-[729px]  rounded-[10px] bg-white`}>
            <div className="h-[130px] flex justify-center items-center relative">
              <img
                alt="logo"
                src={logoImage}
                className="w-[234px] h-[36.08px]"
              />
              <div className="absolute right-0 top-0 p-3">
                <IconButton onClick={handleClose}>
                  <img
                    alt="close-icon"
                    src={closeIcon}
                    style={{ width: 16, height: 16 }}
                  />
                </IconButton>
              </div>
            </div>
            <div>
              <AntTabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <AntTab label="Register" {...a11yProps(0)} />
                <AntTab label="Log in" {...a11yProps(1)} />
              </AntTabs>
              <TabPanel value={tabValue} index={0} dir={theme.direction}>
                <RegisterTab onChangeTab={handleChangeIndex} />
              </TabPanel>
              <TabPanel value={tabValue} index={1} dir={theme.direction}>
                <LoginTab onCloseModal={handleClose} />
              </TabPanel>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
