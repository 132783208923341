import ContactIcon from "@mui/icons-material/ContactPage";
import HomeIcon from "@mui/icons-material/Home";
import StorefrontIcon from "@mui/icons-material/Storefront";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

export default function SidebarLeft({
  isDrawerOpen = false,
  toggleDrawer = () => {},
}) {
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button>
          <ListItemIcon>
            <StorefrontIcon />
          </ListItemIcon>
          <ListItemText primary={"Marketplace"} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ContactIcon />
          </ListItemIcon>
          <ListItemText primary={"Contact"} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Drawer anchor={"left"} open={isDrawerOpen} onClose={toggleDrawer(false)}>
      {list()}
    </Drawer>
  );
}
