import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import FullscreenProgress from "./components/common/FullscreenProgress";
import AlertProvider from "./libs/context/AlertContext";
import ModalLoadingProvider from "./libs/context/ModalLoadingContext";
import Router from "./pages/Router";
import store, { persistor } from "./redux/store";
import theme from "./styles/mui-theme";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <ModalLoadingProvider>
            <AlertProvider>
              <PersistGate
                loading={<FullscreenProgress />}
                persistor={persistor}
              >
                <Router />
              </PersistGate>
            </AlertProvider>
          </ModalLoadingProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
