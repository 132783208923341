import DatePicker from "@mui/lab/DatePicker";
import { Button, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import dateIcon from "../../assets/icons/date.svg";
import { ALERT_TYPE } from "../../libs/context/AlertContext";
import useAlert from "../../libs/hooks/useAlert";
import useModalLoading from "../../libs/hooks/useModalLoading";
import { getErrorMessage, getIsError } from "../../libs/utils/form";
import {
  useSaleVerifyAccountMutation,
  useSaleVerifyAccountUploadMutation,
} from "../../services/auth";
import { useMyProfileQuery } from "../../services/profile";
import HexagonBadge from "../common/HexagonBadge";
import { BootstrapInput } from "../common/TextInput";
import UploadDocument from "./UploadDocument";

const countrys = [
  {
    label: "Thailand",
    value: "th",
  },
  {
    label: "Malaysia",
    value: "my",
  },
  {
    label: "Singapore",
    value: "sg",
  },
];

const VerifyAccountSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required."),
  lastname: Yup.string().required("Last name is required."),
  middlename: Yup.string(),
  dateOfBirth: Yup.date().nullable().required("Date of birth is required."),
  address: Yup.string().required("Address is required."),
  postCode: Yup.string().required("Postcode is required."),
  city: Yup.string().required("City is required."),
  countryId: Yup.string().required("Country is required."),
  countryIdentityId: Yup.string().required("Country is required."),
  files: Yup.object()
    .nullable()
    .shape({
      // "card_front": {},
      // "card_back": {},
      // "passport": {},
      // "driver": {}
    })
    .required("Please upload your document."),
});

export default function VerfiyAccountForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: myProfileData } = useMyProfileQuery();
  const myProfile = (myProfileData && myProfileData[0]) || {};

  const { showAlert, hideAlert } = useAlert();
  const { showLoading, hideLoading } = useModalLoading();

  const [postVerifyAccount, verifyAccountStatus] =
    useSaleVerifyAccountMutation();
  const [uploadDocument, uploadStatus] = useSaleVerifyAccountUploadMutation();

  const formik = useFormik({
    initialValues: {
      firstname: myProfile?.firstname || "",
      lastname: myProfile?.lastname || "",
      middlename: "",
      dateOfBirth: null,
      address: "",
      postCode: "",
      city: "",
      countryId: myProfile?.countryId || "th",
      countryIdentityId: "th",
      files: null,
    },
    enableReinitialize: true,
    validationSchema: VerifyAccountSchema,
    onSubmit: async ({ files, ...values }, actions) => {
      showLoading();
      actions.setSubmitting(false);

      try {
        const uploadResponse = await uploadDocument(files);
        if (uploadResponse.error) throw uploadResponse.error;

        postVerifyAccount({
          ...values,
          postCode: values.postCode + "",
          files: uploadResponse.data || {},
        });
      } catch (error) {
        showAlert({
          type: ALERT_TYPE.ERROR,
          title: "Error !",
          description: _.get(error, "data.message", JSON.stringify(error)),
          button: [{ title: "Close", onClick: hideAlert }],
        });
        hideLoading();
      }
    },
  });

  console.log("verifyAccountStatus ", verifyAccountStatus);

  useEffect(() => {
    if (!verifyAccountStatus.isLoading) {
      if (verifyAccountStatus.isError) {
        hideLoading();
        showAlert({
          type: ALERT_TYPE.ERROR,
          title: "Error !",
          description: _.get(
            verifyAccountStatus.error,
            "data.message",
            JSON.stringify(verifyAccountStatus.error)
          ),
          button: [{ title: "Close", onClick: hideAlert }],
        });
      } else if (verifyAccountStatus.isSuccess) {
        hideLoading();
        showAlert({
          type: ALERT_TYPE.SUCCESS,
          title: "Verify completed",
          description: "Your account has been verifycation successfully",
          button: [
            {
              title: "Close",
              onClick: () => {
                navigate("/");
              },
            },
          ],
        });
        formik.handleReset();
        verifyAccountStatus.reset();
        uploadStatus.reset();
      }
    }
  }, [verifyAccountStatus.isLoading]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <h1 className="text-5xl font-bold text-primary-main">
          Verify your Account
        </h1>
        <p className="text-base mt-12 text-primary-semiLight">
          YPlacerat nec at non ut lectus velit orci, placerat. Imperdiet nisl
          turpis commodo auctor. Consequat duis viverra eu in donec enim lorem
          platea. Eleifend sed massa amet, feugiat sit diam arcu ornare. Nunc,
          diam id a nec neque, et, viverra in nullam. Eget viverra eu feugiat
          enim turpis. Mi rhoncus, consequat ipsum pretium ultrices eu est
          dictum vitae. Egestas suspendisse quis lobortis fermentum et. Cras
          sagittis purus congue lectus sed elementum feugiat. Nisi, facilisi in
          montes, sed blandit. Quam platea sed ipsum fames fringilla massa nisl
          viverra elementum. Penatibus molestie id sollicitudin id cum ornare
          nam. Curabitur eget nunc egestas vestibulum fermentum. Phasellus
          venenatis eu id leo id.
        </p>
        <div className="mt-[100px] md:px-[95px]">
          <HexagonBadge number={1} />
          <h2 className="text-lg font-medium text-primary-main my-6">
            Basic Infomation
          </h2>
          <div className="bg-secondary-main w-[60px] h-[3px] rounded-[3px]" />
          <div className="mt-[60px] mb-[100px] grid grid-cols-1 md:grid-cols-2 gap-8">
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="firstname">
                First Name
              </InputLabel>
              <BootstrapInput
                fullWidth
                size="small"
                id="firstname"
                name="firstname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstname}
                error={getIsError(formik, "firstname")}
                helperText={getErrorMessage(formik, "firstname")}
                placeholder="First Name"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="middlename">
                Middle Name(s) (if applicable)
              </InputLabel>
              <BootstrapInput
                fullWidth
                size="small"
                id="middlename"
                name="middlename"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.middlename}
                error={getIsError(formik, "middlename")}
                helperText={getErrorMessage(formik, "middlename")}
                placeholder="Middle Name"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="lastname">
                Last Name
              </InputLabel>
              <BootstrapInput
                fullWidth
                size="small"
                id="lastname"
                name="lastname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastname}
                error={getIsError(formik, "lastname")}
                helperText={getErrorMessage(formik, "lastname")}
                placeholder="Last Name"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="dateOfBirth">
                Date of birth
              </InputLabel>
              <DatePicker
                id="dateOfBirth"
                name="dateOfBirth"
                onChange={(newValue) => {
                  formik.setFieldValue("dateOfBirth", newValue);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.dateOfBirth}
                keyboardIcon={<img alt="dateIcon" src={dateIcon} />}
                renderInput={(params) => (
                  <BootstrapInput
                    {...params}
                    keyboardIcon={<img alt="dateIcon" src={dateIcon} />}
                    error={getIsError(formik, "dateOfBirth")}
                    helperText={getErrorMessage(formik, "dateOfBirth")}
                  />
                )}
              />
            </FormControl>
            <FormControl variant="standard" className="md:col-span-2">
              <InputLabel shrink htmlFor="address">
                Residential Address
              </InputLabel>
              <BootstrapInput
                fullWidth
                size="small"
                id="address"
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={getIsError(formik, "address")}
                helperText={getErrorMessage(formik, "address")}
                placeholder="Address"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="postalCode">
                Postal Code
              </InputLabel>
              <BootstrapInput
                fullWidth
                size="small"
                id="postCode"
                name="postCode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postCode}
                error={getIsError(formik, "postCode")}
                helperText={getErrorMessage(formik, "postCode")}
                placeholder="Code"
                type="number"
              />
            </FormControl>
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="city">
                City
              </InputLabel>
              <BootstrapInput
                fullWidth
                size="small"
                id="city"
                name="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                error={getIsError(formik, "city")}
                helperText={getErrorMessage(formik, "city")}
                placeholder="City"
              />
            </FormControl>
            <FormControl variant="standard" className="md:col-span-2">
              <InputLabel shrink htmlFor="country">
                Country/Region
              </InputLabel>
              <BootstrapInput
                fullWidth
                select
                size="small"
                id="country"
                name="countryId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.countryId}
                error={getIsError(formik, "countryId")}
                helperText={getErrorMessage(formik, "countryId")}
              >
                {countrys.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </BootstrapInput>
            </FormControl>
          </div>

          <HexagonBadge number={2} />
          <h2 className="text-lg font-medium text-primary-main my-6">
            Identity and Facial Verification
          </h2>
          <div className="bg-secondary-main w-[60px] h-[3px] rounded-[3px]" />
          <div className="mt-[60px] grid grid-cols-1 gap-8">
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="issueCountry">
                Choose issuing country/Region
              </InputLabel>
              <BootstrapInput
                fullWidth
                select
                size="small"
                id="issueCountry"
                name="countryIdentityId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.countryIdentityId}
                error={getIsError(formik, "countryIdentityId")}
                helperText={getErrorMessage(formik, "countryIdentityId")}
              >
                {countrys.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </BootstrapInput>
            </FormControl>
            <div>
              <InputLabel shrink>Select ID type</InputLabel>
              <p className="text-base text-primary-a1">
                Use a valid government-issued photo ID. A residence permit is
                also a valid identity card.
              </p>
              {getIsError(formik, "files") && (
                <FormHelperText error>
                  {JSON.stringify(getErrorMessage(formik, "files"))}
                </FormHelperText>
              )}
              <UploadDocument
                title="Identity card"
                description="Upload identification card"
                onChangeFile={(files) => {
                  formik.setFieldValue("files.card_front", files.front);
                  formik.setFieldValue("files.card_back", files.back);
                }}
              />
              <UploadDocument
                hideBack
                title="Passport"
                description="Upload identification card"
                onChangeFile={(files) => {
                  formik.setFieldValue("files.passport", files.front);
                }}
              />
              <UploadDocument
                hideBack
                title="Driver’s license"
                description="Upload identification card"
                onChangeFile={(files) => {
                  formik.setFieldValue("files.driver", files.front);
                }}
              />
            </div>
            <div className="flex justify-end">
              <Button
                className="w-full md:w-[252px] h-[48px]"
                variant="contained"
                type="submit"
                onClick={() => {
                  if (!formik.isValid || (formik.isValid && !formik.dirty)) {
                    window.scrollTo(0, 0);
                  }
                }}
                disabled={!formik.isValid}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
