import { Button } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { doc, getFirestore } from "firebase/firestore";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import verify from "../../assets/icons/verify.svg";
import crytodeeMarkRightIcon from "../../assets/images/crytodee-mark-right.svg";
import crytodeeMarkIcon from "../../assets/images/crytodee-mark.svg";
import defaultImage from "../../assets/images/defaultImage.svg";
import firebaseApp from "../../libs/Firebase";
import { getImageUrl } from "../../libs/utils/utils";
import { selectUID } from "../../redux/modules/authSlice";
import { useMyProfileQuery, useProfileQuery } from "../../services/profile";
import VerifySellerCard from "./VerifySellerCard";

dayjs.extend(relativeTime);

export default function ProfileSection({ isMyProfile = false }) {
  const { accountCode } = useParams();
  const userId = useSelector(selectUID);
  const { data: myProfile } = useMyProfileQuery(
    {},
    {
      skip: !isMyProfile,
    }
  );
  const { data: profileById } = useProfileQuery(accountCode, {
    skip: isMyProfile,
  });

  const [firebaseUser] = useDocumentData(
    doc(
      getFirestore(firebaseApp),
      `users/${isMyProfile ? userId : accountCode}`
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const data = isMyProfile ? (myProfile || [])[0] : profileById;

  const hightlightList = [
    // {
    //   title: "Chat Perfomance",
    //   value: "80%",
    // },
    {
      title: "Followers",
      value: "1.5K",
    },
    {
      title: "Products",
      value: "40",
    },
  ];

  const imageProfileUrl = data?.files ? getImageUrl(data?.files) : "";

  return (
    <div className="flex flex-col">
      <div className="flex flex-1 flex-col md:flex-row items-center md:items-stretch">
        <img
          alt="profile"
          src={imageProfileUrl || defaultImage}
          className={`w-[225px] h-[225px] bg-gray-735 ${
            imageProfileUrl ? "object-cover" : "object-contain"
          }  rounded-3xl`}
        />
        <div className="flex flex-col flex-1 md:ml-[60px] justify-between items-center md:items-start ">
          <div className="flex flex-row mt-[50px] md:mt-0 ">
            <h1 className="text-5xl font-bold text-primary-main break-all line-clamp-2">
              {`${data?.firstname || ""} ${data?.lastname || ""}`}
            </h1>
            <img src={verify} alt="verify" className="ml-5" />
          </div>
          <div className="mb-4 md:mb-0">
            <p className="text-xl font-bold text-primary-light text-center md:text-left mt-6 md:mt-0">
              Member Untill Fri 14 Nov 2010
            </p>
            <p className="text-xl text-primary-semiLight mt-2 text-center md:text-left">
              Last active{" "}
              {firebaseUser
                ? dayjs(firebaseUser.onlineLasted?.toDate()).fromNow()
                : ""}
            </p>
          </div>
          <Button variant="outlined" color="primary" className="w-[189px]">
            Follow
          </Button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center mt-[72px] md:mt-[100px] mb-[80px] md:mb-[183px] gap-10">
        {hightlightList.map((item, index) => (
          <div key={index} className="flex flex-col items-center flex-1">
            <h1 className="text-5xl font-bold text-center text-primary-main">
              {item.value}
            </h1>
            <h1 className="text-2xl font-bold text-center text-secondary-main mt-4 md:mt-8">
              {item.title}
            </h1>
          </div>
        ))}
      </div>
      <VerifySellerCard isVerify={false} />
      <img
        src={crytodeeMarkIcon}
        alt="crytodee-mark-icon"
        className="hidden md:block z-0 absolute left-0 "
      />
      <img
        src={crytodeeMarkRightIcon}
        alt="crytodee-mark-right-icon"
        className="hidden md:block z-0 absolute bottom-0 right-0"
      />
    </div>
  );
}
