import { AppBar, Container, IconButton, styled, Toolbar } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import menuIcon from "../../assets/icons/menu.svg";
import logo from "../../assets/images/logo.svg";
import RegisterModal from "../loginRegister/RegisterModal";
import ElevationScroll from "./ElevationScroll";
import NavbarMenu from "./NavbarMenu";
import SidebarLeft from "./SidebarLeft";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export default function Header(props) {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar
          position="fixed"
          elevation={0}
          sx={{ bgcolor: "white" }}
          // style={{ zIndex: 10 }}
        >
          <Container style={{ padding: 0 }}>
            <Toolbar className="flex md:justify-between">
              <div className="block md:hidden">
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <img src={menuIcon} alt="logo" className="h-7" />
                </IconButton>
              </div>
              <Link to="/">
                <img src={logo} alt="logo" className="h-4 md:h-5" />
              </Link>
              <NavbarMenu />
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Offset id="back-to-top-anchor" />
      <SidebarLeft isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <RegisterModal />
    </React.Fragment>
  );
}
