import { Container } from "@mui/material";
import * as React from "react";
import Layout from "../components/layout/Layout";
import AllProduct from "../components/product/AllProduct";
import ProfileSection from "../components/profile/ProfileSection";
import PremiumSellerPricing from "../components/shared/PremiumSellerPricing";

export default function Profile({ isMyProfile }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout isDisabledContainer>
      <Container>
        <div className="my-[100px]">
          <ProfileSection isMyProfile={isMyProfile} />
        </div>
        {!isMyProfile && <AllProduct hideMark />}
      </Container>
      <PremiumSellerPricing />
    </Layout>
  );
}
