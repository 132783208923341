import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/layout/Layout";
import AllProduct from "../components/product/AllProduct";
import PremiumSellerPricing from "../components/shared/PremiumSellerPricing";

export default function ProductByCategory() {
  const { categoryId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout isDisabledContainer>
      <Container>
        <h1 className="text-3xl md:text-5xl mt-[45px] font-roboto font-medium text-primary-main">
          Product by category
        </h1>
        <AllProduct hideMark mode="result" categoryId={categoryId} />
      </Container>
      <PremiumSellerPricing />
    </Layout>
  );
}
