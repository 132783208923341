import React from "react";
import { useNavigate } from "react-router-dom";
import n1 from "../../assets/images/mocks/n1.svg";
import n2 from "../../assets/images/mocks/n2.svg";
import n3 from "../../assets/images/mocks/n3.svg";
import n4 from "../../assets/images/mocks/n4.svg";
import r1 from "../../assets/images/mocks/r1.svg";
import r2 from "../../assets/images/mocks/r2.svg";
import r3 from "../../assets/images/mocks/r3.svg";
import { PRODUCT_TYPES } from "../../libs/constants";
import { getProductSkeletonItemLength } from "../../libs/utils/utils";
import { useProductListQuery } from "../../services/product";
import ProductCard from "./ProductCard";

const mockProduct = [
  { url: r1 },
  { url: r2 },
  { url: r3 },
  { url: n1 },
  { url: n2 },
  { url: n3 },
  { url: n4 },
];

export default function ProductRecommend() {
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useProductListQuery({
    page: 1,
    pageLimit: 10,
    type: PRODUCT_TYPES.RECOMMAND,
  });

  const productList =
    isLoading || isFetching
      ? getProductSkeletonItemLength(3, data)
      : data || [];

  const handleClickMoreProduct = () => navigate("/market");

  return (
    <div className="mt-[60px]">
      <div className="flex flex-col md:flex-row justify-between">
        <h2 className="text-3xl font-medium text-primary-main">Recommend</h2>
        <p
          className="text-lg text-secondary-main md:text-primary-main mt-2 cursor-pointer"
          onClick={handleClickMoreProduct}
        >
          View More Products
        </p>
      </div>
      <div className="mt-[40px] flex flex-row overflow-x-scroll space-x-4 md:space-x-[140px]">
        {productList.map((product, index) => (
          <ProductCard
            key={index}
            {...product}
            isLoading={isLoading || isFetching}
          />
        ))}
      </div>
    </div>
  );
}
