import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import AllProduct from "../components/product/AllProduct";
import PremiumSellerPricing from "../components/shared/PremiumSellerPricing";
import SearchBarProduct from "../components/shared/SearchBarProduct";
import useQueryParams from "../libs/hooks/useQueryParams";

export default function ProductSearch() {
  const queryParams = useQueryParams();
  const categoryIdParams = queryParams.get("categoryId");
  const searchTextParams = queryParams.get("searchText");

  const [categoryId, setCategoryId] = useState(categoryIdParams || "");
  const [searchText, setSearchText] = useState(searchTextParams || "");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOnSubmitSearch = (values) => {
    setCategoryId(values.categoryId);
    setSearchText(values.searchText);
  };

  return (
    <Layout isDisabledContainer>
      <Container>
        <h1 className="text-3xl md:text-5xl mt-[45px] font-roboto font-medium text-primary-main">
          Search product
        </h1>
        <SearchBarProduct
          defaultCategoryId={categoryIdParams}
          defaultSearchText={searchTextParams}
          onSubmit={handleOnSubmitSearch}
        />
        <AllProduct
          hideMark
          mode="result"
          categoryId={categoryId}
          searchText={searchText}
        />
      </Container>
      <PremiumSellerPricing />
    </Layout>
  );
}
