import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { authApi } from "../services/auth";
import { binanceApi } from "../services/binance";
import { categoriesApi } from "../services/categories";
import { productApi } from "../services/product";
import { profileApi } from "../services/profile";
import { authReducer } from "./modules/authSlice";
import { chatReducer } from "./modules/chatSlice";
import { counterReducer } from "./modules/counterSlice";
import { uiReducer } from "./modules/uiSlice";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    ui: uiReducer,
    chat: chatReducer,
    [authApi.reducerPath]: authApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [binanceApi.reducerPath]: binanceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authApi.middleware,
      categoriesApi.middleware,
      profileApi.middleware,
      productApi.middleware,
      binanceApi.middleware
    ),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export default store;
