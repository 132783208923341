import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import verifySellerIcon from "../../assets/icons/verify-seller-icon.svg";
import verifySellerBg from "../../assets/images/verify-seller-bg.svg";

export default function VerifySellerCard({ isVerify = false }) {
  return (
    <div>
      <div className=" bg-primary-main overflow-hidden rounded-t-3xl flex flex-col items-center p-8">
        <img alt="verifySellerIcon" src={verifySellerIcon} />
        <h4 className="text-4xl md:text-6xl font-bold text-secondary-main mt-4">
          Verify <span className="text-white">Seller</span>
        </h4>
        <p className="text-lg text-center text-white mt-4">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes.
        </p>
        <img
          alt="verifySellerBg"
          src={verifySellerBg}
          className="w-full absolute"
        />
        {!isVerify && (
          <div className="mt-4">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              LinkComponent={Link}
              to="/verify-account"
            >
              Verify now
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
