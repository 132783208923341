import React from "react";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/icons/arrowLeft.svg";

export default function BackPageButton({ backUrl = -1, title = "Go back" }) {
  const navigate = useNavigate();
  const handleClick = () => navigate(backUrl);
  return (
    <div className="flex flex-row gap-4 cursor-pointer" onClick={handleClick}>
      <img alt="arrowLeft" src={arrowLeft} />
      <p className=" font-roboto font-medium text-primary-main">{title}</p>
    </div>
  );
}
