import Search from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { useCategoryListQuery } from "../../services/categories";

export default function SearchBarProduct({
  defaultCategoryId = "",
  defaultSearchText = "",
  onSubmit = () => {},
}) {
  const [categoryId, setCategoryId] = useState(defaultCategoryId || "");
  const [searchText, setSearchText] = useState(defaultSearchText || "");

  const { data } = useCategoryListQuery();
  const categories = data || [];

  const handleCategoryChange = (event) => {
    setCategoryId(event.target.value);
  };

  const handleSearchProductChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleMouseDownSearch = (event) => {
    event.preventDefault();
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    onSubmit({ categoryId, searchText });
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="mt-[60px] bg-gray-f1 p-5 md:px-[60px] space-y-3 md:space-y-0 md:space-x-4 rounded flex flex-col md:flex-row">
        <FormControl className="w-full md:w-[205px] md:min-w-[205px] bg-white">
          <Select
            labelId="select-category-label"
            id="select-category"
            value={categoryId}
            onChange={handleCategoryChange}
            displayEmpty
          >
            <MenuItem value="">All Category</MenuItem>
            {categories.map((categorie) => (
              <MenuItem key={categorie.categoryId} value={categorie.categoryId}>
                {categorie.categoryName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="w-full bg-white ">
          <InputLabel htmlFor="outlined-adornment-search-product">
            Search Products
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-search-product"
            type="text"
            onChange={handleSearchProductChange}
            value={searchText}
            endAdornment={
              <InputAdornment position="end">
                <div className="md:hidden">
                  <IconButton
                    type="submit"
                    aria-label="toggle search-product visibility"
                    onMouseDown={handleMouseDownSearch}
                    edge="end"
                  >
                    <Search />
                  </IconButton>
                </div>
              </InputAdornment>
            }
            label="Search Products"
          />
        </FormControl>
        <div className="hidden md:flex">
          <Button
            variant="contained"
            color="primary"
            className="w-[150px]"
            type="submit"
          >
            <Search />
            <span className="flex flex-1 justify-center">Search</span>
          </Button>
        </div>
      </div>
    </form>
  );
}
