import { Button, Checkbox, Link, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import facebookIcon from "../../assets/icons/facebook-back.svg";
import gmailIcon from "../../assets/icons/gmail-back.svg";
import { userTypes } from "../../libs/constants";
import { ALERT_TYPE } from "../../libs/context/AlertContext";
import useAlert from "../../libs/hooks/useAlert";
import useModalLoading from "../../libs/hooks/useModalLoading";
import { getErrorMessage, getIsError } from "../../libs/utils/form";
import { authSlice } from "../../redux/modules/authSlice";
import { useLoginMutation } from "../../services/auth";
import { BootstrapInput } from "../common/TextInput";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required."),
  password: Yup.string().required("Password is required."),
});

export default function LoginTab({ onCloseModal }) {
  const dispatch = useDispatch();
  const [userType, setUserTypes] = useState("BUYER");
  const [rememberMe, setRememberMe] = useState(true);
  const isBuyer = userType === "BUYER";

  const { showAlert, hideAlert } = useAlert();
  const { showLoading, hideLoading } = useModalLoading();

  const [login, { isLoading, isSuccess, isError, data, reset, error }] =
    useLoginMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      showLoading();
      login({ accountType: userType, ...values });
    },
  });

  useEffect(() => {
    if (!isLoading) {
      if (isError) {
        hideLoading();
        showAlert({
          type: ALERT_TYPE.ERROR,
          title: "Error !",
          description: _.get(error, "data.message", JSON.stringify(error)),
          button: [{ title: "Close", onClick: hideAlert }],
        });
      } else if (isSuccess) {
        hideLoading();
        dispatch(
          authSlice.actions.updateAccessToken({
            accessToken: data.token,
            rememberMe,
          })
        );
        formik.handleReset();
        reset();
        onCloseModal();
      }
    }
  }, [isLoading, isError, error]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>
        <FormControl variant="standard" required className="col-span-2">
          <InputLabel shrink htmlFor="userType">
            Type
          </InputLabel>
          <BootstrapInput
            fullWidth
            select
            size="small"
            id="userType"
            defaultValue={userType}
            value={userType}
            onChange={(e) => {
              setUserTypes(e.target.value);
            }}
          >
            {userTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </BootstrapInput>
        </FormControl>

        <FormControl variant="standard" required className="col-span-2">
          <InputLabel shrink htmlFor="email">
            Email address/User name
          </InputLabel>
          <BootstrapInput
            fullWidth
            size="small"
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={getIsError(formik, "email")}
            helperText={getErrorMessage(formik, "email")}
            placeholder="Email address/User name"
          />
        </FormControl>
        <FormControl variant="standard" required className="col-span-2">
          <InputLabel shrink htmlFor="password">
            Password
          </InputLabel>
          <BootstrapInput
            fullWidth
            size="small"
            id="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={getIsError(formik, "password")}
            helperText={getErrorMessage(formik, "password")}
            type="password"
            placeholder="Password"
          />
        </FormControl>
        <FormControl variant="standard" required>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                style={{ borderRadius: 300 }}
                checked={rememberMe}
                onChange={() => {
                  setRememberMe(!rememberMe);
                }}
              />
            }
            label="Remember me"
          />
        </FormControl>

        <div className="flex justify-end items-center">
          <Link href="#" target="_blank" variant="body2">
            Forgot Password?
          </Link>
        </div>
        {isBuyer && (
          <div className="col-span-2">
            <p className="text-center">or</p>
            <div className="flex justify-between gap-[24px] mt-4">
              <Button
                variant="outlined"
                fullWidth
                disableElevation={false}
                className="h-[51px] shadow-lg"
              >
                <img
                  alt="facebook-icon"
                  src={facebookIcon}
                  style={{ width: 24, height: 24 }}
                />
                <span className="ml-4"> Log in with Facebook</span>
              </Button>
              <Button
                variant="outlined"
                fullWidth
                disableElevation={false}
                className="h-[51px] shadow-lg"
              >
                <img
                  alt="gmail-icon"
                  src={gmailIcon}
                  style={{ width: 24, height: 24 }}
                />

                <span className="ml-4"> Log in with Google</span>
              </Button>
            </div>
          </div>
        )}
        <div className="flex justify-center mt-4 col-span-2">
          <Button
            className="w-full md:w-[150px] h-[48px]"
            variant="contained"
            type="submit"
          >
            Login
          </Button>
        </div>
      </div>
    </form>
  );
}
