import { Button, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { userTypes } from "../../libs/constants";
import { ALERT_TYPE } from "../../libs/context/AlertContext";
import useAlert from "../../libs/hooks/useAlert";
import useModalLoading from "../../libs/hooks/useModalLoading";
import { useSignupMutation } from "../../services/auth";
import { BootstrapInput } from "../common/TextInput";
import RegisterBuyerForm from "./registerForm/RegisterBuyerForm";
import RegisterDealerForm from "./registerForm/RegisterDealerForm";

const BuyerSignupSchema = Yup.object().shape({
  firstname: Yup.string().required("Your name is required."),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required."),
  password: Yup.string()
    .min(8, "Your password too Short!")
    .required("Password is required."),
  phoneNumber: Yup.string()
    .min(9, "Invalid phone number")
    .required("Phone number is required."),
});
const DealerSignupSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required."),
  lastname: Yup.string().required("Last name is required."),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required."),
  password: Yup.string()
    .min(8, "Your password too Short!")
    .required("Password is required."),
  confirmPassword: Yup.string()
    .min(8, "Your password too Short!")
    .required("Password is required.")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  country: Yup.string().required("Country is required."),
  phoneNumber: Yup.string()
    .min(9, "Invalid phone number")
    .required("Phone number is required."),
});

export default function RegisterTab({ onChangeTab }) {
  const [userType, setUserTypes] = useState("BUYER");
  const isBuyer = userType === "BUYER";
  const { showAlert, hideAlert } = useAlert();
  const { showLoading, hideLoading } = useModalLoading();

  const [signup, { isLoading, isSuccess, isError, reset, error }] =
    useSignupMutation();

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmPassword: "",
      country: "th", // .
      codeNumber: "+66",
      phoneNumber: "",
    },
    validationSchema: isBuyer ? BuyerSignupSchema : DealerSignupSchema,
    onSubmit: (values) => {
      showLoading();
      signup({ accountType: userType, ...values });
    },
  });

  useEffect(() => {
    if (!isLoading) {
      hideLoading();
      if (isError) {
        showAlert({
          type: ALERT_TYPE.ERROR,
          title: "Error !",
          description: _.get(error, "data.message", JSON.stringify(error)),
        });
      } else if (isSuccess) {
        showAlert({
          type: ALERT_TYPE.SUCCESS,
          title: "Register completed",
          description: "Your account has been created successfully",
          button: [
            { title: "Close", onClick: hideAlert },
            {
              title: "Go to login",
              buttonProps: { variant: "contained" },
              onClick: () => {
                formik.handleReset();
                reset();
                hideAlert();
                onChangeTab(1);
              },
            },
          ],
        });
      }
    }
  }, [isLoading, isError, error]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>
        <FormControl variant="standard" required className="col-span-2">
          <InputLabel shrink htmlFor="userType">
            Type
          </InputLabel>
          <BootstrapInput
            fullWidth
            select
            size="small"
            id="userType"
            defaultValue={userType}
            value={userType}
            onChange={(e) => {
              setUserTypes(e.target.value);
            }}
          >
            {userTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </BootstrapInput>
        </FormControl>
        {isBuyer ? (
          <RegisterBuyerForm formik={formik} />
        ) : (
          <RegisterDealerForm formik={formik} />
        )}

        <div className="flex justify-center mt-4 col-span-2">
          <Button
            className="w-full md:w-[150px] h-[48px]"
            variant="contained"
            type="submit"
          >
            Register
          </Button>
        </div>
      </div>
    </form>
  );
}
