const baseImageUrl = "https://crypt-dee-api.knacx.dev";

export const getImageUrl = (files) =>
  files?.path ? `${baseImageUrl}/${files.path}` : "";

export const getProductSkeletonItemLength = (amountItem = 4, data = []) => {
  return new Array(data?.length || amountItem).fill({});
};

export const convertPrice = (amount, priceRate) => {
  try {
    return parseFloat(
      parseFloat(amount || 0.0) / parseFloat(priceRate || 0.0)
    ).toFixed(8);
  } catch (error) {
    return "0";
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      console.log("Called", reader);
      baseURL = reader.result;
      console.log(baseURL);
      resolve(baseURL);
    };
    console.log(fileInfo);
  });
};
