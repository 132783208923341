import { InputAdornment } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { collection, getFirestore, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate, useParams } from "react-router-dom";
import angleDownIcon from "../../assets/icons/angle-down.svg";
import searchIcon from "../../assets/icons/search.svg";
import firebaseApp, { getUserByid } from "../../libs/Firebase";
import TextInput from "../common/TextInput";

dayjs.extend(relativeTime);

const Badge = ({ children }) => (
  <div className="ml-2 py-[2px] px-2 rounded-3xl bg-secondary-main font-bold text-xs text-white ">
    {children}
  </div>
);

function UnReadBadge({ roomId, myUserId, onCountChat }) {
  const [countChatData] = useCollectionData(
    query(
      collection(getFirestore(firebaseApp), `rooms/${roomId}/messages`),
      where("userId", "!=", myUserId)
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const countChat = countChatData
    ? countChatData.filter((c) => !c.read.some((v) => v === myUserId)).length
    : 0;

  useEffect(() => {
    onCountChat(countChat, roomId);
  }, [countChatData]);

  return countChat && countChat !== 0 ? <Badge>{countChat}</Badge> : null;
}

export default function RoomList({ myUserId }) {
  const navigate = useNavigate();
  const { roomId } = useParams();

  const [searchText, setSearchText] = useState("");
  const [rooms, setRooms] = useState([]);
  const [allCountChat, setAllCountChat] = useState({});

  const [value, loading, error] = useCollectionData(
    query(
      collection(getFirestore(firebaseApp), "rooms"),
      where("roomUsers", "array-contains-any", [myUserId])
    ),
    {
      idField: "roomId",
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (!loading && value) {
      const getRoomWithUser = async () => {
        const roomWithUser = await Promise.all(
          value.map(async (v) => ({
            ...v,
            roomUsers: await Promise.all(v.roomUsers.map(getUserByid)),
          }))
        );

        const mapReceiverUser = roomWithUser.map((room) => {
          const receiverUser =
            room.roomUsers.find((user) => user?.userId !== myUserId) || {};

          return {
            ...room,
            ...receiverUser,
            onlineLasted: receiverUser?.onlineLasted?.toDate(),
          };
        });
        setRooms(mapReceiverUser);
      };

      getRoomWithUser();
    }
  }, [loading, value]);

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setSearchText("");
  };

  const handleChildrenCountChat = (countChat, roomId) => {
    setAllCountChat({
      ...allCountChat,
      [roomId]: countChat,
    });
  };

  const totalCountChat = Object.values(allCountChat).reduce((p, c) => p + c, 0);

  return (
    <div className="h-[650px] w-[350px]">
      <div className="h-[650px] w-[350px] bg-gray-735 border-r-[1px] flex flex-col">
        <div className="h-[80px]">
          <div className="h-[80px] w-full flex flex-row justify-center items-center ">
            <h5 className="font-medium text-lg text-primary">Messages</h5>
            <img alt="angle-down" src={angleDownIcon} className="ml-[6px]" />
            {totalCountChat && totalCountChat !== 0 ? (
              <Badge>{totalCountChat}</Badge>
            ) : null}
          </div>
        </div>
        <div className="bg-primary-main opacity-[0.08] h-[1px] w-full" />
        <form onSubmit={handleSubmitSearch}>
          <div className="py-3 px-4">
            <TextInput
              placeholder="Search message"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img alt="search" src={searchIcon} />
                  </InputAdornment>
                ),
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </form>
        <div className="w-full p-4 overflow-hidden  overflow-y-scroll space-y-2">
          {/* <FirestoreDocument /> */}
          {(rooms || []).map((room, index) => (
            <div
              key={index}
              className={`flex flex-row w-full p-3 rounded-xl  cursor-pointer ${
                roomId === room.roomId ? "bg-white" : ""
              }`}
              onClick={() => navigate(`/chat/room/${room.roomId}`)}
            >
              <Avatar
                alt={room.name || "Profile"}
                src={room.imageUrl || "/"}
                sx={{
                  width: 48,
                  height: 48,
                }}
                variant="rounded"
              />
              <div className="ml-4 flex flex-col flex-1">
                <div className="flex flex-row items-start">
                  <h6 className="font-medium flex flex-1 text-base text-primary line-clamp-1">
                    {room.name}
                  </h6>
                  <p className="text-xs text-primary-semiLight">
                    {room.onlineLasted
                      ? dayjs(room.onlineLasted).fromNow()
                      : ""}
                  </p>
                </div>
                <p className="text-sm text-primary-semiLight">{room.caption}</p>
              </div>
              <div>
                <UnReadBadge
                  myUserId={myUserId}
                  roomId={room.roomId}
                  onCountChat={handleChildrenCountChat}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
