import React from "react";
import c1 from "../../assets/images/mocks/c1.svg";
import c2 from "../../assets/images/mocks/c2.svg";
import c3 from "../../assets/images/mocks/c3.svg";
import c4 from "../../assets/images/mocks/c4.svg";
import n1 from "../../assets/images/mocks/n1.svg";
import n2 from "../../assets/images/mocks/n2.svg";
import n3 from "../../assets/images/mocks/n3.svg";
import n4 from "../../assets/images/mocks/n4.svg";
import r1 from "../../assets/images/mocks/r1.svg";
import r2 from "../../assets/images/mocks/r2.svg";
import r3 from "../../assets/images/mocks/r3.svg";
import { PRODUCT_TYPES } from "../../libs/constants";
import { getProductSkeletonItemLength } from "../../libs/utils/utils";
import { useProductListQuery } from "../../services/product";
import ProductCard from "./ProductCard";

const mockProduct = [
  { url: c1 },
  { url: c2 },
  { url: c3 },
  { url: c4 },
  { url: n1 },
  { url: n2 },
  { url: n3 },
  { url: n4 },
  { url: r1 },
  { url: r2 },
  { url: r3 },
];

export default function ProductRelated() {
  const { data, isLoading, isFetching } = useProductListQuery({
    page: 1,
    pageLimit: 10,
    type: PRODUCT_TYPES.RELATED,
  });

  const productList =
    isLoading || isFetching
      ? getProductSkeletonItemLength(4, data)
      : data || [];

  return (
    <div className="mt-[44px]">
      <div className="flex flex-col md:flex-row ">
        <h2 className="text-3xl font-medium text-primary-main">
          Product Related
        </h2>
        <div className="hidden md:flex flex-row ml-10"></div>
      </div>
      <div className="mt-6 flex flex-col md:flex-row overflow-x-scroll space-x-4 md:space-x-[30px]">
        {productList.map((product, index) => (
          <ProductCard
            key={index}
            {...product}
            isLoading={isLoading || isFetching}
          />
        ))}
      </div>
    </div>
  );
}
