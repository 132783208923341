import { Container } from "@mui/material";
import * as React from "react";
import { useParams } from "react-router-dom";
import BackPageButton from "../components/common/BackPageButton";
import Layout from "../components/layout/Layout";
import ProductRelated from "../components/product/ProductRelated";
import ProductDetailSection from "../components/productDetail/ProductDetailSection";
import ProductImageSection from "../components/productDetail/ProductImageSection";
import PremiumSellerPricing from "../components/shared/PremiumSellerPricing";
import { getImageUrl } from "../libs/utils/utils";
import { useProductDetailQuery } from "../services/product";

export default function Product() {
  const params = useParams();
  const productId = params.productId;

  const { data } = useProductDetailQuery(productId);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productDetail = data || {};

  return (
    <Layout isDisabledContainer>
      <Container>
        <BackPageButton title="Back to Marketplaceplace" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-[60px] mb-[120px]">
          <ProductImageSection
            imageList={productDetail?.files?.map((c) => ({
              url: getImageUrl(c),
            }))}
          />
          <ProductDetailSection {...productDetail} />
        </div>
        <ProductRelated />
      </Container>
      <PremiumSellerPricing />
    </Layout>
  );
}
