import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderWidth: 1,
      },
    },
  },
  input: {
    fontSize: 16,
  },
  notchedOutline: {
    borderWidth: 0,
  },
}));

export const BootstrapInput = styled(TextField)(
  ({ theme, paddingLeftInput = 32 }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiOutlinedInput-root": {
      height: 48,
      backgroundColor: "rgba(7, 35, 68, 0.1)",
      fieldset: {
        borderColor: "rgba(7, 35, 68, 0.1)",
        // borderWidth: 0,
      },
    },

    "& .MuiInputBase-input": {
      paddingLeft: `${paddingLeftInput}px`,
      borderRadius: 4,
      position: "relative",
      //   // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      //   // border: "1px solid #ced4da",
      fontSize: 16,
      //   width: "auto",
      //   padding: "10px 12px",
      //   paddingLeft: "32px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      // fontFamily: [
      //   "-apple-system",
      //   "BlinkMacSystemFont",
      //   '"Segoe UI"',
      //   "Roboto",
      //   '"Helvetica Neue"',
      //   "Arial",
      //   "sans-serif",
      //   '"Apple Color Emoji"',
      //   '"Segoe UI Emoji"',
      //   '"Segoe UI Symbol"',
      // ].join(","),
      "&:focus": {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        // backgroundColor: "rgba(7, 35, 68, 0.1)",
        borderColor: theme.palette.primary.main,
      },
    },
  })
);

export default function TextInput(props) {
  const classes = useStyles();
  return (
    <TextField
      className={classes.root}
      fullWidth
      size="small"
      {...props}
      InputProps={{
        classes: {
          input: classes.input,
          notchedOutline: classes.notchedOutline,
        },
        ...props.InputProps,
      }}
    />
  );
}
