import { IconButton } from "@mui/material";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import expandLeft from "../../assets/icons/expand-left.svg";
import expandRight from "../../assets/icons/expand-right.svg";
import defaultImage from "../../assets/images/defaultImage.svg";
import c1 from "../../assets/images/mocks/c1.svg";
import c2 from "../../assets/images/mocks/c2.svg";
import c3 from "../../assets/images/mocks/c3.svg";
import c4 from "../../assets/images/mocks/c4.svg";
import n1 from "../../assets/images/mocks/n1.svg";
import n2 from "../../assets/images/mocks/n2.svg";
import n3 from "../../assets/images/mocks/n3.svg";
import n4 from "../../assets/images/mocks/n4.svg";
import r1 from "../../assets/images/mocks/r1.svg";
import r2 from "../../assets/images/mocks/r2.svg";
import r3 from "../../assets/images/mocks/r3.svg";

const imageListMock = [
  { url: c1 },
  { url: c2 },
  { url: c3 },
  { url: c4 },
  { url: n1 },
  { url: n2 },
  { url: n3 },
  { url: n4 },
  { url: r1 },
  { url: r2 },
  { url: r3 },
];

export default function ProductImageSection({ imageList = [] }) {
  const [selectIndex, setSelectIndex] = useState(0);
  const [isOpenPreview, setOpenPreview] = useState(false);

  const currentImage = imageList[selectIndex] || {};

  const handlePreventPage = () => {
    if (selectIndex === 0) return;
    setSelectIndex(selectIndex - 1);
  };
  const handleNextPage = () => {
    if (selectIndex === imageList.length - 1) return;
    setSelectIndex(selectIndex + 1);
  };

  const images = imageList.map((c) => c.url);

  return (
    <div>
      <img
        alt="currentImage"
        src={currentImage.url || defaultImage}
        className={`w-full h-[278px] md:h-[484px] bg-gray-375 ${
          currentImage.url ? "object-cover" : "object-contain"
        } cursor-pointer bg-gray-50`}
        onClick={() => setOpenPreview(true)}
      />
      <div className="flex flex-row gap-1 mt-3 md:mt-6 overflow-x-scroll">
        {imageList.map((item, index) => (
          <img
            key={index}
            alt={`img-${index + 1}`}
            src={item.url}
            className="w-[25%] h-[79px] md:w-[25%] md:h-[138px] bg-gray-375 object-cover cursor-pointer"
            onClick={() => setSelectIndex(index)}
          />
        ))}
      </div>
      {imageList.length ? (
        <div className="flex flex-row justify-end items-center mt-2 md:mt-3">
          <IconButton onClick={handlePreventPage}>
            <img alt="expandLeft" src={expandLeft} />
          </IconButton>
          <p className="text-sm font-medium text-primary-semiLight">
            {selectIndex + 1} / {imageList.length}
          </p>
          <IconButton onClick={handleNextPage}>
            <img alt="expandRight" src={expandRight} />
          </IconButton>
        </div>
      ) : null}
      {isOpenPreview && (
        <Lightbox
          reactModalStyle={{ zIndex: 9999999 }}
          mainSrc={images[selectIndex] || ""}
          nextSrc={images[(selectIndex + 1) % images.length] || ""}
          prevSrc={
            images[(selectIndex + images.length - 1) % images.length] || ""
          }
          onCloseRequest={() => setOpenPreview(false)}
          onMovePrevRequest={handlePreventPage}
          onMoveNextRequest={handleNextPage}
        />
      )}
    </div>
  );
}
