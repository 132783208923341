import { Container } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import ChatRoom from "../components/chat/ChatRoom";
import RoomList from "../components/chat/RoomList";
import Layout from "../components/layout/Layout";
import PremiumSellerPricing from "../components/shared/PremiumSellerPricing";
import { selectUID } from "../redux/modules/authSlice";

export default function Chat({ isEmpty }) {
  const myUserId = useSelector(selectUID);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout isDisabledContainer>
      <Container>
        <h1 className="text-3xl md:text-5xl mt-[45px] font-roboto font-medium text-primary-main">
          Chat
        </h1>
        <div className="flex flex-row">
          <RoomList myUserId={myUserId} />
          <ChatRoom myUserId={myUserId} isEmpty={isEmpty} />
        </div>
      </Container>
      <PremiumSellerPricing />
    </Layout>
  );
}
