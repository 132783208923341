import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { makeStyles, useTheme } from "@mui/styles";
import React, { createContext } from "react";

export const ALERT_TYPE = {
  MESSAGE: "message",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const AlertContext = createContext({
  isOpen: false,
  showAlert: (
    info = {
      type: ALERT_TYPE.MESSAGE,
      title: "",
      description: "",
      button: [{ title: "Close" }],
    }
  ) => {},
  hideAlert: () => {},
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyle = makeStyles((theme) => ({
  wrapTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  titleSpacer: {
    width: theme.spacing(2),
  },
}));

export default function AlertProvider({ children }) {
  const classes = useStyle();
  const theme = useTheme();
  const [isOpen, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    type: ALERT_TYPE.MESSAGE,
    title: "",
    description: "",
    button: [{ title: "Close" }],
  });

  const showAlert = (info) => {
    setMessage({ ...message, ...info });
    setOpen(true);
  };

  const hideAlert = () => {
    setOpen(false);
  };

  const getTitleIcon = () => {
    switch (message.type) {
      case ALERT_TYPE.ERROR:
        return <ErrorIcon />;
      case ALERT_TYPE.SUCCESS:
        return <CheckCircleIcon />;
      case ALERT_TYPE.INFO:
        return <InfoIcon />;
      case ALERT_TYPE.WARNING:
        return <WarningIcon />;
      default:
        return null;
    }
  };

  const getTitleColor = () => {
    switch (message.type) {
      case ALERT_TYPE.ERROR:
        return theme.palette.error.main;
      case ALERT_TYPE.SUCCESS:
        return theme.palette.success.main;
      case ALERT_TYPE.INFO:
        return theme.palette.info.main;
      case ALERT_TYPE.WARNING:
        return theme.palette.warning.main;
      default:
        return null;
    }
  };

  const titleIcon = getTitleIcon();
  return (
    <AlertContext.Provider
      value={{
        isOpen,
        showAlert,
        hideAlert,
      }}
    >
      {children}
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted={false}
        fullWidth
        maxWidth="xs"
        onClose={hideAlert}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ zIndex: 99999 }}
      >
        <DialogTitle>
          <div className={classes.wrapTitle} style={{ color: getTitleColor() }}>
            {titleIcon ? (
              <>
                {titleIcon}
                <div className={classes.titleSpacer} />
              </>
            ) : null}
            {message.title}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {message.button.map((button, index) => (
            <Button
              key={index}
              color="primary"
              onClick={() => {
                hideAlert();
                if (button.onClick) button.onClick();
              }}
              {...button.buttonProps}
            >
              {button.title}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </AlertContext.Provider>
  );
}
