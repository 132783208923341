import React from "react";
import builder from "../../assets/images/mocks/builder.svg";
import carBanner from "../../assets/images/mocks/carBanner.svg";
import BannerRectangle from "../common/BannerRectangle";
import BannerSquare from "../common/BannerSquare";

export default function AdsBanner() {
  return (
    <div className="flex flex-col md:flex-row gap-4 mt-[44px]">
      <BannerRectangle
        title="Real Estate"
        description="One Thing for all your comfort and flexibled."
        image={builder}
      />
      <BannerSquare
        title="Car"
        description="Explore car accessories."
        image={carBanner}
      />
    </div>
  );
}
