import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import env from "../libs/configs/env";
import { prepareHeaders } from "./utils";

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: fetchBaseQuery({ baseUrl: env.baseUrl, prepareHeaders }),
  tagTypes: [],
  endpoints: (builder) => ({
    categoryList: builder.query({
      query: (body) => ({ url: `/api/categories`, method: "GET", body }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useCategoryListQuery } = categoriesApi;
