// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyALM39lZ243Of8MQCRlChQb-gdr7-OIStE",
  authDomain: "chatapp-885fc.firebaseapp.com",
  projectId: "chatapp-885fc",
  storageBucket: "chatapp-885fc.appspot.com",
  messagingSenderId: "837971614656",
  appId: "1:837971614656:web:2c5ef3f77daaa036c08625",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// export default firebase;
const db = getFirestore(firebaseApp);

// Utils...
const getDocData =
  (idField = "id") =>
  (doc) => {
    return { ...doc.data(), [idField]: doc.id };
  };

const getDataIfExists = (snapshot, idField) => {
  if (snapshot.exists()) {
    return getDocData(idField)(snapshot);
  }
  return null;
};

const findOne = (snapshot, idField) => {
  if (!snapshot.empty) {
    return snapshot.docs.map(getDocData(idField))[0];
  }
  return null;
};

const findAll = (snapshot, idField) => {
  if (!snapshot.empty) {
    return snapshot.docs.map(getDocData(idField));
  }
  return [];
};

/** ----------  Bussiness logic  ---------- */

export async function getUserByid(userId) {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  return getDataIfExists(docSnap, "userId");
}

export async function getUserByUserRefId(userRefId) {
  const docRef = collection(db, "users");
  const q = query(docRef, where("userId", "==", userRefId));
  const docSnap = await getDocs(q);
  const data = findOne(docSnap, "userId");
  return data;
}

export async function getRoomsByUserId(userId) {
  const docRef = collection(db, "rooms");
  const q = query(docRef, where("roomUsers", "array-contains-any", [userId]));
  const docSnap = await getDocs(q);

  const dataList = await Promise.all(
    docSnap.docs.map(async (doc) => ({
      ...doc.data(),
      roomId: doc.id,
      roomUsers: await Promise.all(doc.data().roomUsers.map(getUserByid)),
    }))
  );

  return dataList;
}

export async function getOrCreateRoomBetweenUsers(
  targetUserRefId,
  currentUserId
) {
  const targetUser = await getUserByUserRefId(targetUserRefId);
  if (!targetUser) return targetUser;

  const docRef = collection(db, "rooms");
  const q = query(
    docRef,
    where("roomUsers", "in", [[currentUserId, targetUser.userId]])
  );
  const docSnap = await getDocs(q);
  const room = findOne(docSnap, "roomId");

  if (room) return room.roomId;

  const newRoom = await addDoc(collection(db, "rooms"), {
    name: currentUserId + " " + targetUser.userId,
    roomUsers: [currentUserId, targetUser.userId],
    type: "single",
  });

  return newRoom.id;
}
export async function sendMessage(data) {
  const newDoc = await addDoc(collection(db, `rooms/${data.roomId}/messages`), {
    ...data,
    read: [data.userId],
    createDate: serverTimestamp(),
  });
  return newDoc;
}

export async function createOrUpdateUser({
  userId = "",
  name = "",
  imageUrl = "",
  caption = "",
}) {
  const res = await setDoc(doc(db, "users", userId), {
    caption: caption || "-",
    imageUrl: imageUrl || "",
    name: name || "",
    onlineStatus: "online",
    onlineLasted: serverTimestamp(),
    userId,
  });

  return res;
}

export async function userOffline(userId) {
  const res = await updateDoc(doc(db, "users", userId), {
    onlineStatus: "offline",
    onlineLasted: serverTimestamp(),
  });

  return res;
}

export async function firebaseLogin(token) {
  const auth = getAuth();

  const functions = getFunctions();
  connectFunctionsEmulator(functions, "localhost", 5001);

  const createCustomToken = httpsCallable(functions, "createCustomToken");
  const createCustomTokenRes = await createCustomToken(token);
  const customToken = createCustomTokenRes?.data.customToken;
  const userCredential = await signInWithCustomToken(auth, customToken);
  const user = userCredential.user;
  await createOrUpdateUser();
  return user;
}

export async function firebaseLogout() {
  const auth = getAuth();
  await userOffline();
  return await auth.signOut();
}

export async function readMessage(roomId, userId) {
  const docRef = collection(db, `rooms/${roomId}/messages`);
  const q = query(docRef, where("read", "not-in", [[userId]]));
  const docSnap = await getDocs(q);
  const docSnapRes = await docSnap.forEach(async function (snapshotItem) {
    console.log(snapshotItem.id);
    const res = await updateDoc(
      doc(db, `rooms/${roomId}/messages/${snapshotItem.id}`),
      {
        read: arrayUnion(userId),
      }
    );
    return res;
  });

  return docSnapRes;
}

export default firebaseApp;
