import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import env from "../libs/configs/env";
import { prepareHeaders } from "./utils";

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({ baseUrl: env.baseUrl, prepareHeaders }),
  tagTypes: [],
  endpoints: (builder) => ({
    productList: builder.query({
      query: (params) => ({
        url: `/api/products`,
        method: "GET",
        params,
        responseHandler: async (res) => {
          // console.log("responseHandler", res.headers.get("content-range"));

          for (var pair of res.headers.entries()) {
            // console.log(pair[0] + ": " + pair[1]);
          }

          const data = await res.json();
          // console.log("res.json().", data);
          const sss = data.map((c) => ({
            ...c,
            foo: res.headers.get("content-type"),
          }));
          return sss;
        },
      }),
    }),
    productDetail: builder.query({
      query: (productId) => ({
        url: `/api/products/${productId}`,
        method: "GET",
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useProductListQuery, useProductDetailQuery } = productApi;
