import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import crytodeeMarkIcon from "../../assets/images/crytodee-mark.svg";
import n1 from "../../assets/images/mocks/n1.svg";
import n2 from "../../assets/images/mocks/n2.svg";
import n3 from "../../assets/images/mocks/n3.svg";
import n4 from "../../assets/images/mocks/n4.svg";
import r1 from "../../assets/images/mocks/r1.svg";
import r2 from "../../assets/images/mocks/r2.svg";
import r3 from "../../assets/images/mocks/r3.svg";
import { PRODUCT_TYPES } from "../../libs/constants";
import { getProductSkeletonItemLength } from "../../libs/utils/utils";
import { useProductListQuery } from "../../services/product";
import ProductCard from "./ProductCard";

const mockProduct = [
  { url: n1 },
  { url: n2 },
  { url: n3 },
  { url: n4 },
  { url: r1 },
  { url: r2 },
  { url: r3 },
];

const productTypes = [
  { name: "Lasted Products", value: PRODUCT_TYPES.LASTED },
  { name: "Best seller", value: PRODUCT_TYPES.BESTSELLTER },
  { name: "Hot Sale", value: PRODUCT_TYPES.HOTSALE },
];

export default function NewArrivalItem({ hideMark = false }) {
  const [productType, setProductType] = useState(PRODUCT_TYPES.LASTED);
  const { data, isLoading, isFetching } = useProductListQuery(
    {
      page: 1,
      pageLimit: 10,
      type: productType,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const productList =
    isLoading || isFetching
      ? getProductSkeletonItemLength(4, data)
      : data || [];

  const handleproductTypeChange = (event) => setProductType(event.target.value);

  return (
    <div className="mt-[44px]">
      <div className="flex flex-col md:flex-row ">
        <h2 className="text-3xl font-medium text-primary-main">New Arrival</h2>
        <div className="mt-6 md:hidden">
          <FormControl className="w-full bg-white">
            <Select
              labelId="select-productType-label"
              id="select-productType"
              value={productType}
              onChange={handleproductTypeChange}
              displayEmpty
            >
              {productTypes.map((type) => (
                <MenuItem key={type.name} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="hidden md:flex flex-row ml-10">
          {productTypes.map((type) => (
            <MenuItem
              key={type.value}
              onClick={() => {
                setProductType(type.value);
              }}
            >
              <p
                className={`${
                  productType === type.value
                    ? "text-secondary-main"
                    : "text-primary-main"
                } font-medium`}
              >
                {type.name}
              </p>
            </MenuItem>
          ))}
        </div>
      </div>
      <div className="mt-6 flex flex-row overflow-x-scroll space-x-4 md:space-x-[30px]">
        {productList.map((product, index) => (
          <ProductCard
            key={index}
            {...product}
            isLoading={isLoading || isFetching}
          />
        ))}
      </div>
      {!hideMark && (
        <img
          src={crytodeeMarkIcon}
          alt="crytodee-mark-icon"
          className="hidden md:block z-0 absolute left-0 -mt-[800px] -ml-[80px]"
        />
      )}
    </div>
  );
}
