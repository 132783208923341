import { selectAccessToken } from "../redux/modules/authSlice";

export function validateStatus(response, result) {
  return (
    (response.status === 200 || response.status === 201) && !result.isError
  );
}

export function prepareHeaders(headers, { getState }) {
  const accessToken = selectAccessToken(getState());

  // If we have a token set in state, let's assume that we should be passing it.
  if (accessToken) {
    headers.set("Authorization", `Bearer ${accessToken}`);
  } else {
    // headers.set("Authorization", "");
  }

  return headers;
}

export async function transformResponse(response, meta, arg) {
  if (response.status !== 201) {
    const text = await response.json();
    console.log("text ", text);
    return text.length ? JSON.parse(text) : text || null;
  }

  return response;
}
