import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import React from "react";
import emailIcon from "../../../assets/icons/email-back.svg";
import facebookIcon from "../../../assets/icons/facebook-back.svg";
import gmailIcon from "../../../assets/icons/gmail-back.svg";
import { getErrorMessage, getIsError } from "../../../libs/utils/form";
import { BootstrapInput } from "../../common/TextInput";

const phoneCode = [
  {
    label: "Thailand",
    value: "+66",
  },
  {
    label: "Malaysia",
    value: "+60",
  },
  {
    label: "Singapore",
    value: "+65",
  },
];

export default function RegisterBuyerForm({ formik }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <FormControl variant="standard" required className="col-span-2">
        <InputLabel shrink htmlFor="firstName">
          Your name
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="firstName"
          name="firstname"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstname}
          error={getIsError(formik, "firstname")}
          helperText={getErrorMessage(formik, "firstname")}
          placeholder="Your name"
        />
      </FormControl>
      <FormControl variant="standard" required className="col-span-2">
        <InputLabel shrink htmlFor="email">
          Email address
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={getIsError(formik, "email")}
          helperText={getErrorMessage(formik, "email")}
          placeholder="Email address"
        />
      </FormControl>
      <FormControl variant="standard" required className="col-span-2">
        <InputLabel shrink htmlFor="phoneNumber">
          Mobile number
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="phoneNumber"
          name="phoneNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phoneNumber}
          error={getIsError(formik, "phoneNumber")}
          helperText={getErrorMessage(formik, "phoneNumber")}
          placeholder="Mobile number"
          paddingLeftInput={8}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClick}
                  edge="end"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
                <p className="text-primary-main ml-[16px]">
                  {formik.values.codeNumber}
                </p>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        value={formik.values.codeNumber}
      >
        {phoneCode.map((option) => (
          <MenuItem
            key={option.value}
            selected={formik.values.codeNumber === option.value}
            onClick={() => {
              handleClose();
              formik.setFieldValue("codeNumber", option.value);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <FormControl variant="standard" required className="col-span-2">
        <InputLabel shrink htmlFor="password">
          Password
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="password"
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={getIsError(formik, "password")}
          helperText={getErrorMessage(formik, "password")}
          type="password"
          placeholder="Password"
        />
      </FormControl>
      <div className="col-span-2">
        <p className="text-center">or</p>
        <div className="flex justify-center gap-[24px] mt-4">
          <IconButton>
            <img
              alt="facebook-icon"
              src={facebookIcon}
              style={{ width: 24, height: 24 }}
            />
          </IconButton>
          <IconButton>
            <img
              alt="gmail-icon"
              src={gmailIcon}
              style={{ width: 24, height: 24 }}
            />
          </IconButton>
          <IconButton>
            <img
              alt="email-icon"
              src={emailIcon}
              style={{ width: 24, height: 24 }}
            />
          </IconButton>
        </div>
      </div>
    </>
  );
}
