export const userTypes = [
  {
    label: "Buyer",
    value: "BUYER",
  },
  {
    label: "Dealer",
    value: "DEALER",
  },
];

export const PRODUCT_TYPES = {
  LASTED: "LASTED",
  BESTSELLTER: "BESTSELLTER",
  HOTSALE: "HOTSALE",
  RECOMMAND: "RECOMMAND",
  RELATED: "RELATED",
};
