import React from "react";

export default function BannerSquare({ title, description, image }) {
  return (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(242, 152, 61, 0.8), rgba(242, 152, 61, 0.8)), url("${image}")`,
        backgroundBlendMode: "multiply, normal",
        backgroundSize: "cover",
        backgroundPosition: 'center'
      }}
      className="h-[250px] w-auto md:w-[250px] rounded flex flex-col justify-center items-center"
    >
      <p className="text-base font-medium text-center text-white">{title}</p>
      <p className="text-2xl font-medium text-center text-white mt-4 w-min-content">
        {description}
      </p>
    </div>
  );
}
