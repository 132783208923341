import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import env from "../libs/configs/env";
import { prepareHeaders, transformResponse, validateStatus } from "./utils";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.baseUrl,
    prepareHeaders,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (body) => ({ url: `/api/signup`, method: "POST", body }),
    }),
    login: builder.mutation({
      query: (body) => ({ url: `/api/login`, method: "POST", body }),
    }),
    logout: builder.mutation({
      query: (body) => ({ url: `/api/logout`, method: "POST" }),
    }),
    saleVerifyAccount: builder.mutation({
      query: (body) => ({
        url: `/api/sale-verify`,
        method: "POST",
        body,
        validateStatus,
        responseHandler: transformResponse,
      }),
    }),
    saleVerifyAccountUpload: builder.mutation({
      query: (body) => {
        const formData = new FormData();

        if (body.card_front && body.card_back) {
          formData.append("card_front", body.card_front?.files[0]);
          formData.append("card_back", body.card_back?.files[0]);
        }
        if (body.passport) {
          formData.append("passport", body.passport?.files[0]);
        }
        if (body.driver) {
          formData.append("driver", body.driver?.files[0]);
        }
        return {
          url: `/api/sale-verify/upload`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useSignupMutation,
  useLoginMutation,
  useLogoutMutation,
  useSaleVerifyAccountMutation,
  useSaleVerifyAccountUploadMutation,
} = authApi;
