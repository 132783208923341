import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BTCIcon } from "../../assets/icons/btc-inner.svg";
import { ReactComponent as ETHIcon } from "../../assets/icons/eth-inner.svg";
import shibaCoinIcon from "../../assets/icons/shiba-coin.svg";
import usdtIcon from "../../assets/icons/usdt.svg";
import verify from "../../assets/icons/verify.svg";
import { ReactComponent as ZCOINIcon } from "../../assets/icons/zcoin-inner.svg";
import { ALERT_TYPE } from "../../libs/context/AlertContext";
import { getOrCreateRoomBetweenUsers } from "../../libs/Firebase";
import useAlert from "../../libs/hooks/useAlert";
import { convertPrice, getImageUrl } from "../../libs/utils/utils";
import { selectAccessToken, selectUID } from "../../redux/modules/authSlice";
import { chatSlice } from "../../redux/modules/chatSlice";
import { usePriceRateQuery } from "../../services/binance";

const coinIcons = {
  BTC: BTCIcon,
  ETH: ETHIcon,
  ZEC: ZCOINIcon,
};

const coinNaming = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  ZEC: "ZCash",
};

export default function ProductDetailSection({
  productId = null, // number
  accountCode = "",
  topic = "",
  productName = "",
  productDescription = "",
  productPrice = 49.33,
  unit = "USDT",
  supportCoin = ["BTC", "ETH", "ZEC"],
  files = {},
  accountVerify = null,
  account = {},
  onClickChatNow = () => {},
  onClickDetail = () => {},
  // ---
}) {
  const dispatch = useDispatch();
  const { showAlert, hideAlert } = useAlert();
  const currentUserId = useSelector(selectUID);
  const accessToken = useSelector(selectAccessToken);
  // const targetUserRefId = "oet9LNZSqN"; // accountCode
  const targetUserRefId = accountCode;

  const [selectedCrypto, setSelectCrypto] = useState("BTC");
  const navigate = useNavigate();
  const priceRate = usePriceRateQuery(`${selectedCrypto}USDT`, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 10000,
  });

  const cryptoPriceConvert = convertPrice(
    productPrice,
    priceRate?.data?.price || 0
  );

  const productUrl = getImageUrl(files && files[0]);
  const seller = account.firstname
    ? `${account.firstname} ${account.lastname}`
    : "";

  const handleClickChatNow = async () => {
    if (!accessToken) {
      showAlert({
        type: ALERT_TYPE.WARNING,
        title: "Your never login.",
        description:
          "Please back to login or register for continue chat with dealer.",
        button: [{ title: "Close", onClick: hideAlert }],
      });
      return;
    }

    if (currentUserId === targetUserRefId) {
      return navigate(`/chat`);
    }

    onClickChatNow();
    const roomId = await getOrCreateRoomBetweenUsers(
      currentUserId,
      targetUserRefId
    );
    if (productUrl) {
      dispatch(chatSlice.actions.setProductImageUrl(productUrl));
    }
    navigate(`/chat/room/${roomId}`);
  };

  return (
    <div className="p-4 min-w-[287px]">
      <h5 className="mt-5 text-base font-medium text-primary-main">
        {productName}
      </h5>
      <p className="mt-2 text-base text-primary-light">{productDescription}</p>
      <p className="text-base mt-5 text-primary-semiLight">Standard Price</p>
      <h2 className="text-2xl mt-2 font-bold text-primary-main">{`${
        productPrice || 0
      } ${unit}`}</h2>
      <p className="text-base mt-8 text-primary-semiLight">
        Select Crpto Price what you want to pay.
      </p>
      <div className="flex flex-row space-x-2 mt-4">
        {supportCoin.map((coin) => {
          const CoinIcons = coinIcons[coin];

          return (
            <IconButton key={coin} onClick={() => setSelectCrypto(coin)}>
              <div
                className={`${
                  selectedCrypto === coin
                    ? "bg-secondary-main"
                    : "bg-secondary-light"
                } flex items-center justify-center rounded-full`}
                style={{ width: 60, height: 60 }}
              >
                <CoinIcons
                  style={{ width: 24, height: 24 }}
                  color={selectedCrypto === coin ? "white" : "#F2983D"}
                />
              </div>
            </IconButton>
          );
        })}
      </div>
      <p className="text-base mt-5 text-primary-semiLight">
        {coinNaming[selectedCrypto]}
      </p>
      <h2 className="text-2xl font-bold text-primary-main">{`${cryptoPriceConvert} ${selectedCrypto}`}</h2>
      <p className="text-base mt-8 text-primary-semiLight">You can pay pass</p>
      <div className="flex flex-row space-x-2 mt-4">
        <img
          src={usdtIcon}
          alt="usdt"
          className="w=[50px] h-[50px] rounded-full"
        />
        <img
          src={shibaCoinIcon}
          alt="shibaCoin"
          className="w=[50px] h-[50px] rounded-full"
        />
      </div>
      <div className="mt-6 flex flex-row">
        <img src={verify} alt="verify" />
        <div className="ml-1">
          <p className="text-sm text-primary-semiLight">
            {accountVerify ? "Verificate Seller" : "Not Verificate"}
          </p>
          <p className="text-base font-medium text-primary-main">
            {seller || "-"}
          </p>
        </div>
      </div>
      <div className="mt-6" />
      <Button
        variant="contained"
        color="primary"
        size="large"
        className="w-full md:w-[285px] h-[48px]"
        onClick={handleClickChatNow}
      >
        Chat Now!
      </Button>
      <div className="mt-2" />
    </div>
  );
}
