import { Button, ButtonBase, IconButton, InputAdornment } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import {
  collection,
  doc,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import chatCloseShortcutIcon from "../../assets/icons/chat-close-shortcut.svg";
import chatOpenShortcutIcon from "../../assets/icons/chat-open-shortcut.svg";
import paperPlaneIcon from "../../assets/icons/paper-plane.svg";
import phoneIcon from "../../assets/icons/phone.svg";
import profileIcon from "../../assets/icons/profile.svg";
import firebaseApp, {
  getUserByid,
  readMessage,
  sendMessage,
} from "../../libs/Firebase";
import useModalLoading from "../../libs/hooks/useModalLoading";
import { getBase64 } from "../../libs/utils/utils";
import {
  chatSlice,
  selectChatProductImageUrl,
} from "../../redux/modules/chatSlice";
import { useProfileQuery } from "../../services/profile";
import TextInput from "../common/TextInput";

function ChatImage({ url = "" }) {
  return (
    <div>
      <img
        alt="chat-img"
        src={url}
        className="w-[360px] h-auto bg-gray-f1 rounded"
      />
    </div>
  );
}

function Message({ message, isSender }) {
  const bg = isSender ? "bg-primary-main" : "bg-gray-f1";
  const color = isSender ? "text-white" : "text-primary-a1";
  return (
    <div className={`${bg} rounded-xl px-4 py-2`}>
      <p className={`text-base ${color}`}>{message}</p>
    </div>
  );
}

function ProfileSection({ userId }) {
  const [receiverUser] = useDocumentData(
    doc(getFirestore(firebaseApp), `users/${userId}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const statusColor =
    receiverUser?.onlineStatus === "online" ? "bg-green-400" : "bg-red-400";

  return (
    <div className="flex flex-row p-3 w-full ">
      <Avatar
        alt={receiverUser?.name || "Profile"}
        src={receiverUser?.imageUrl || "/"}
        sx={{ width: 48, height: 48, bgcolor: "#F2983D" }}
        variant="rounded"
      />
      <div className="ml-4 flex flex-1 flex-col">
        <div className="flex flex-row justify-between items-start ">
          <h6 className="font-medium text-base text-primary line-clamp-1">
            {receiverUser?.name || "-"}
          </h6>
        </div>
        <div className="flex flex-row justify-start items-center">
          <div className={`${statusColor} w-[10px] h-[10px] rounded-[10px]`} />
          <p className="text-sm text-primary-semiLight ml-2">
            {receiverUser?.onlineStatus || "-"}
          </p>
        </div>
      </div>
    </div>
  );
}

function ChatMessage({
  name = "",
  message = "",
  imageUrl = "",
  imageProfileUrl = "",
  type = "message", // image | message
  userId,
  read = false,
  // -- js manipulate
  isSender = false,
  shouldDisplayProfile = false,
}) {
  return (
    <div
      className={`flex flex-row w-full ${
        isSender ? "flex-row-reverse" : ""
      } gap-4 mt-4`}
    >
      <div style={{ width: 40, height: 40 }}>
        {shouldDisplayProfile && (
          <Avatar
            alt={name || "Profile"}
            src={imageProfileUrl || "/"}
            sx={{
              width: 48,
              height: 48,
              bgcolor: isSender ? "#072344" : "#F2983D",
            }}
            variant="rounded"
          />
        )}
      </div>
      <div className="space-y-[10px] max-w-[85%]">
        {type === "image" ? (
          <ChatImage url={imageUrl} />
        ) : (
          <Message isSender={isSender} message={message} />
        )}
      </div>
    </div>
  );
}

const mapDisplayProfile = (messages) =>
  messages.reduce((prev, current, i) => {
    if (i === 0) return [...prev, { ...current, shouldDisplayProfile: true }];

    if (prev[prev.length - 1].isSender !== current.isSender) {
      return [...prev, { ...current, shouldDisplayProfile: true }];
    }
    return [...prev, { ...current, shouldDisplayProfile: false }];
  }, []);

const mapIsSender = (messages, currentUserId) =>
  messages.map((message) => ({
    ...message,
    isSender: message.userId === currentUserId,
  }));

export default function ChatRoom({ isEmpty, myUserId }) {
  const { roomId } = useParams();
  const { showLoading, hideLoading } = useModalLoading();
  const dispatch = useDispatch();
  const messageList = [];
  const scrollMessagesRef = useRef();
  const [messages, setMessages] = useState(messageList);
  const [message, setMessage] = useState("");
  const [isShortcutVisible, setShortcutVisible] = useState(false);
  const [isTriggerScroll, setTriggerScroll] = useState(false);
  const [users, setUsers] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const chatProductImageUrl = useSelector(selectChatProductImageUrl);

  const [value, loading, error] = useCollectionData(
    query(
      collection(getFirestore(firebaseApp), `rooms/${roomId}/messages`),
      orderBy("createDate", "asc")
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [roomDetail] = useDocumentData(
    doc(getFirestore(firebaseApp), `rooms/${roomId}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (roomDetail) {
      const getRoomWithUser = async () => {
        const res = await Promise.all(roomDetail.roomUsers.map(getUserByid));
        setUsers(res.reduce((p, c) => ({ ...p, [c.userId]: c }), {}));
      };

      getRoomWithUser();
    }
  }, [roomDetail]);

  const currentUserId = myUserId;

  useEffect(() => {
    if (chatProductImageUrl) {
      sendMessage({
        message: "",
        imageUrl: chatProductImageUrl,
        type: "image",
        read: false,
        userId: currentUserId,
        roomId,
      });
      setTriggerScroll(!isTriggerScroll);
      dispatch(chatSlice.actions.setProductImageUrl(""));
    }
  }, [chatProductImageUrl]);

  useEffect(() => {
    if (!loading) {
      setMessages(value || []);
      setTriggerScroll(!isTriggerScroll);
    }
  }, [value, loading]);

  useEffect(() => {
    if (scrollMessagesRef.current) {
      scrollMessagesRef.current.scroll({
        // behavior: "smooth" ,
        top: scrollMessagesRef.current.scrollHeight,
      });
    }
  }, [isTriggerScroll]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!message) return;
    sendMessage({
      message: message,
      imageUrl: "",
      type: "message",
      read: false,
      userId: currentUserId,
      roomId,
    });
    setMessage("");
    setTriggerScroll(!isTriggerScroll);
  };

  const handleOnChangeText = (e) => setMessage(e.target.value);
  const handleClickToggleShortcut = (e) =>
    setShortcutVisible(!isShortcutVisible);

  const handleOnFocus = () => {
    readMessage(roomId, myUserId);
  };

  const shortcuts = [
    { title: "Image", type: "uploadImage" },
    // { title: "Shortcut" },
    // { title: "Shortcut" },
    // { title: "Shortcut" },
    // { title: "Shortcut" },
    // { title: "Shortcut" },
  ];

  const onFileChange = async (event) => {
    showLoading();
    try {
      const file = event.target.files[0];
      const base64 = await getBase64(file);
      sendMessage({
        message: "",
        imageUrl: base64,
        type: "image",
        read: false,
        userId: currentUserId,
        roomId,
      });
      setTriggerScroll(!isTriggerScroll);
      setShortcutVisible(false);
      hideLoading();
    } catch (error) {
      hideLoading();
    }
  };

  const handleClickShotcut = () => () => {};

  const chatList = mapDisplayProfile(mapIsSender(messages, currentUserId));

  const receiverUser =
    Object.values(users).find((v) => v.userId !== currentUserId) || {};

  const receiverUserProfileById = useProfileQuery(receiverUser.userId);

  if (isEmpty) {
    return (
      <div className="h-[650px] w-full bg-gray-f9 flex flex-col relative">
        <div className="h-[80px]"></div>
        <div className="bg-primary-main opacity-[0.08] h-[1px] w-full" />
        <div className="flex flex-1 justify-center items-center">
          <h6 className="font-medium text-xl text-primary line-clamp-1">
            Chat room is empty
          </h6>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[650px] w-full bg-gray-f9 flex flex-col relative">
      <div className="h-[80px]">
        <div className="h-[80px] w-full flex flex-row justify-start items-center  px-6">
          <ProfileSection userId={receiverUser?.userId} />
          <div className="flex flex-row space-x-4">
            <Button
              variant="contained"
              color="paperGray1"
              className="bg-gray-735"
              style={{ width: 110, height: 42, borderRadius: 8 }}
              LinkComponent={Link}
              to={`/profile/${receiverUser?.userId}`}
              target="_blank"
            >
              <img
                alt="profile"
                src={profileIcon}
                style={{ width: 22, height: 22, marginRight: 8 }}
              />
              <h6 className="font-medium w-full text-base text-primary">
                Profile
              </h6>
            </Button>
            <Button
              variant="contained"
              color="paperGray1"
              className="bg-gray-735"
              style={{ width: 90, height: 42, borderRadius: 8 }}
              onClick={() => {
                if (
                  receiverUserProfileById?.data &&
                  receiverUserProfileById?.data
                ) {
                  const receiverUserProfileByIdData =
                    receiverUserProfileById?.data;
                  const phoneClipboard = `${receiverUserProfileByIdData?.codenumber}${receiverUserProfileByIdData?.phoneNumber}`;

                  navigator.clipboard.writeText(phoneClipboard);

                  setSnackbar({
                    open: true,
                    message: `Copy phone number: ${phoneClipboard} to clipboard`,
                  });
                }
              }}
            >
              <img
                alt="phone"
                src={phoneIcon}
                style={{ width: 22, height: 22, marginRight: 8 }}
              />
              <h6 className="font-medium w-full text-base text-primary">
                Call
              </h6>
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-primary-main opacity-[0.08] h-[1px] w-full" />

      {/* <div className="bg-blue-800  w-[100%]"> */}
      <div
        ref={scrollMessagesRef}
        className="flex flex-1 w-full flex-col p-6 overflow-y-scroll"
      >
        {chatList.map((message) => (
          <ChatMessage
            {...message}
            imageProfileUrl={users[message.userId]?.imageUrl || ""}
            name={users[message.userId]?.name || ""}
          />
        ))}
      </div>

      {isShortcutVisible && (
        <div className="h-[119px] w-full bg-gray-735 border-b-[1px]">
          <div className="flex h-full items-center justify-start px-4 space-x-4">
            {shortcuts.map((shortcut, i) => {
              if (shortcut.type === "uploadImage") {
                return (
                  <div key={i}>
                    <input
                      style={{ display: "none" }}
                      id={`contained-button-file-${i}`}
                      type="file"
                      onChange={onFileChange}
                    />
                    <label htmlFor={`contained-button-file-${i}`}>
                      <ButtonBase component="span">
                        <div className="h-[71px] w-[77px] rounded-xl flex flex-col justify-center items-center  bg-white">
                          <img
                            alt="profile"
                            src={profileIcon}
                            style={{ width: 22, height: 22 }}
                          />
                          <p className="text-sm mt-1">{shortcut.title}</p>
                        </div>
                      </ButtonBase>
                    </label>
                  </div>
                );
              }

              return (
                <ButtonBase key={i} onClick={handleClickShotcut(shortcut)}>
                  <div className="h-[71px] w-[77px] rounded-xl flex flex-col justify-center items-center  bg-white">
                    <img
                      alt="profile"
                      src={profileIcon}
                      style={{ width: 22, height: 22 }}
                    />
                    <p className="text-sm mt-1">{shortcut.title}</p>
                  </div>
                </ButtonBase>
              );
            })}
          </div>
        </div>
      )}
      <div className="h-[96px] w-full bg-gray-f5">
        <form
          onSubmit={handleOnSubmit}
          className="flex flex-1 h-[100%] items-center p-0 pr-6 m-0"
        >
          <IconButton onClick={handleClickToggleShortcut}>
            <img
              alt="chatShortCutTools"
              style={{ width: 40, height: 40 }}
              src={
                isShortcutVisible ? chatCloseShortcutIcon : chatOpenShortcutIcon
              }
            />
          </IconButton>
          <TextInput
            placeholder="Type a message"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <img
                      alt="search"
                      style={{ width: 20, height: 20 }}
                      src={paperPlaneIcon}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={message}
            onChange={handleOnChangeText}
            onFocus={handleOnFocus}
          />
        </form>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }

          setSnackbar({ open: false, message: "" });
        }}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackbar.message}</span>}
      />
    </div>
  );
}
