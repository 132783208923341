import { Button, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as CategoryIcon } from "../../assets/icons/categoryIcon.svg";
import categoryBg from "../../assets/images/categoryBg.svg";
import crytodeeMarkRightIcon from "../../assets/images/crytodee-mark-right.svg";
import { useCategoryListQuery } from "../../services/categories";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 504,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default function Category() {
  const classes = useStyles();
  const { data } = useCategoryListQuery();
  const categories = data || [];

  return (
    <Container classes={{ root: classes.container }}>
      <div className="relative h-[504px] pt-8 px-2 rounded overflow-hidden bg-gray-f8">
        <img
          src={categoryBg}
          alt="categoryBg"
          className="absolute top-0 left-0 right-0 object-cover h-[504px] w-full"
        />
        <div>
          <div className="flex flex-row px-12">
            <div className="flex-1">
              <h4 className="text-2xl font-roboto font-medium text-center md:text-left">
                Category
              </h4>
              <p className="font-roboto text-sm text-primary-light text-center md:text-left mt-1">
                Buy & sell Product pass CryptDee in minutes
              </p>
            </div>
            <div className="mt-7 hidden md:flex justify-center">
              <Button LinkComponent={Link} to="/market">
                More...
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-4 md:gap-10 gap-y-8 mt-9 ">
            {categories.map(({ categoryName, categoryId }, index) => (
              <Button
                key={index}
                LinkComponent={Link}
                to={`/product/search?categoryId=${categoryId}`}
              >
                <div className="flex justify-center items-center flex-col">
                  <CategoryIcon
                    className={
                      index % 2 === 0
                        ? "fill-primary-main"
                        : "fill-secondary-main"
                    }
                    alt="category-icon"
                  />

                  <span className="mt-5 font-medium">{categoryName}</span>
                </div>
              </Button>
            ))}
          </div>
          <div className="mt-4 flex justify-center md:hidden">
            <Button LinkComponent={Link} to="/market">
              <span className="font-medium">More...</span>
            </Button>
          </div>
        </div>
      </div>
      <img
        src={crytodeeMarkRightIcon}
        alt="crytodee-mark-right-icon"
        className="hidden md:block z-0 absolute -bottom-[260px] right-0"
      />
    </Container>
  );
}
