import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import env from "../libs/configs/env";
import { prepareHeaders } from "./utils";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.baseUrl,
    prepareHeaders,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    myProfile: builder.query({
      query: (body) => ({ url: `/api/profile`, method: "GET" }),
    }),
    profile: builder.query({
      query: (id) => ({ url: `/api/profile/${id}`, method: "GET" }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useMyProfileQuery, useProfileQuery } = profileApi;
