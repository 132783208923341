import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Fab from "@mui/material/Fab";
import * as React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import ScrollTop from "../common/ScrollTop";

export default function Layout({
  children,
  isDisabledContainer = false,
  ...props
}) {
  const WrapperContainer = isDisabledContainer ? React.Fragment : Container;

  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <WrapperContainer>
        <Box sx={{ my: 2 }}>{children}</Box>
      </WrapperContainer>
      <Footer />
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
