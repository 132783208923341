import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { createOrUpdateUser, userOffline } from "../libs/Firebase";
import { getImageUrl } from "../libs/utils/utils";
import { authSlice, selectAccessToken } from "../redux/modules/authSlice";
import { useMyProfileQuery } from "../services/profile";
import About from "./About";
import Chat from "./Chat";
import Home from "./Home";
import Market from "./Market";
import Product from "./Product";
import ProductByCategory from "./ProductByCategory";
import ProductSearch from "./ProductSearch";
import Profile from "./Profile";
import VerifyAccount from "./VerifyAccount";

function Router() {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken);

  const userProfile = useMyProfileQuery({}, { skip: !accessToken });

  useEffect(() => {
    if (userProfile && userProfile.data) {
      const profileData = userProfile?.data[0] || {};
      dispatch(authSlice.actions.updateUID(profileData?.accountCode || ""));
      createOrUpdateUser({
        userId: profileData?.accountCode || "",
        name: `${profileData?.firstname || ""} ${profileData?.lastname || ""}`,
        imageUrl: getImageUrl(profileData?.file),
        caption: profileData?.caption,
      });

      return () => {
        userOffline(profileData?.accountCode);
      };
    }
  }, [dispatch, userProfile]);

  useEffect(() => {
    const unloadCallback = (event) => {
      // event.preventDefault();
      // event.returnValue = "";

      const profileData = userProfile?.data[0] || {};

      if (userProfile && userProfile.data) {
        userOffline(profileData?.accountCode);
      }

      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [userProfile]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/market" element={<Market />} />
      <Route path="/contact" element={<Market />} />
      <Route path="/about" element={<About />} />
      <Route path="/product/:productId" element={<Product />} />
      <Route path="/product/search" element={<ProductSearch />} />
      <Route
        path="/product/category/:categoryId"
        element={<ProductByCategory />}
      />
      <Route path="/profile/:accountCode" element={<Profile />} />
      {accessToken && (
        <>
          <Route path="/my-profile" element={<Profile isMyProfile />} />
          <Route path="/verify-account" element={<VerifyAccount />} />
          <Route path="/chat" element={<Chat isEmpty />} />
          <Route path="/chat/room/:roomId" element={<Chat />} />
        </>
      )}
    </Routes>
  );
}

export default Router;
