import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import React from "react";
import * as Yup from "yup";
import { getErrorMessage, getIsError } from "../../../libs/utils/form";
import { BootstrapInput } from "../../common/TextInput";

const countrys = [
  {
    label: "Thailand",
    value: "th",
  },
  {
    label: "Malaysia",
    value: "my",
  },
  {
    label: "Singapore",
    value: "sg",
  },
];

const phoneCode = [
  {
    label: "Thailand",
    value: "+66",
  },
  {
    label: "Malaysia",
    value: "+60",
  },
  {
    label: "Singapore",
    value: "+65",
  },
];

const BuyerSignupSchema = Yup.object().shape({
  firstname: Yup.string().required("Your name is required."),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required."),
  password: Yup.string()
    .min(8, "Your password too Short!")
    .required("Password is required."),
  phoneNumber: Yup.string()
    .min(9, "Invalid phone number")
    .required("Phone number is required."),
});
const DealerSignupSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required."),
  lastname: Yup.string().required("Last name is required."),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required."),
  password: Yup.string()
    .min(8, "Your password too Short!")
    .required("Password is required."),
  confirmPassword: Yup.string()
    .min(8, "Your password too Short!")
    .required("Password is required.")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  country: Yup.string().required("Country is required."),
  phoneNumber: Yup.string()
    .min(9, "Invalid phone number")
    .required("Phone number is required."),
});

export default function RegisterDealerForm({ formik }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <FormControl variant="standard" required className="">
        <InputLabel shrink htmlFor="firstName">
          First name
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="firstName"
          name="firstname"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstname}
          error={getIsError(formik, "firstname")}
          helperText={getErrorMessage(formik, "firstname")}
          placeholder="First name"
        />
      </FormControl>
      <FormControl variant="standard" required className="">
        <InputLabel shrink htmlFor="lastName">
          Last name
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="lastName"
          name="lastname"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastname}
          error={getIsError(formik, "lastname")}
          helperText={getErrorMessage(formik, "lastname")}
          placeholder="Last name"
        />
      </FormControl>
      <FormControl variant="standard" required className="col-span-2">
        <InputLabel shrink htmlFor="email">
          Email address
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={getIsError(formik, "email")}
          helperText={getErrorMessage(formik, "email")}
          placeholder="Email address"
        />
      </FormControl>
      <FormControl variant="standard" required className="col-span-2">
        <InputLabel shrink htmlFor="password">
          Password
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="password"
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={getIsError(formik, "password")}
          helperText={getErrorMessage(formik, "password")}
          type="password"
          placeholder="Password"
        />
      </FormControl>
      <FormControl variant="standard" required className="col-span-2">
        <InputLabel shrink htmlFor="confirmPassword">
          Confirm Password
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="confirmPassword"
          name="confirmPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
          error={getIsError(formik, "confirmPassword")}
          helperText={getErrorMessage(formik, "confirmPassword")}
          type="password"
          placeholder="Confirm Password"
        />
      </FormControl>
      <FormControl variant="standard" required className="col-span-2">
        <InputLabel shrink htmlFor="country">
          Country
        </InputLabel>
        <BootstrapInput
          fullWidth
          select
          size="small"
          id="country"
          name="country"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.country}
          error={getIsError(formik, "country")}
          helperText={getErrorMessage(formik, "country")}
        >
          {countrys.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </BootstrapInput>
      </FormControl>

      <FormControl variant="standard" required className="col-span-2">
        <InputLabel shrink htmlFor="phoneNumber">
          Mobile number
        </InputLabel>
        <BootstrapInput
          fullWidth
          size="small"
          id="phoneNumber"
          name="phoneNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phoneNumber}
          error={getIsError(formik, "phoneNumber")}
          helperText={getErrorMessage(formik, "phoneNumber")}
          placeholder="Mobile number"
          paddingLeftInput={8}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClick}
                  edge="end"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
                <p className="text-primary-main ml-[16px]">+66</p>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {phoneCode.map((option) => (
          <MenuItem key={option.value} onClick={handleClose}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
