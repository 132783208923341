import { Container } from "@mui/material";
import qs from "qs";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AdsBanner from "../components/landing/AdsBanner";
import BannerHome from "../components/landing/BannerHome";
import Category from "../components/landing/Category";
import Layout from "../components/layout/Layout";
import NewArrivalItem from "../components/product/NewArrivalItem";
import ProductRecommend from "../components/product/ProductRecommend";
import PremiumSellerPricing from "../components/shared/PremiumSellerPricing";
import SearchBarProduct from "../components/shared/SearchBarProduct";

export default function Home() {
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOnSubmitSearch = ({ categoryId, searchText }) => {
    const params = qs.stringify({
      ...(categoryId && { categoryId }),
      ...(searchText && { searchText }),
    });
    navigate(`/product/search?${params}`);
  };

  return (
    <Layout isDisabledContainer>
      <Container>
        <BannerHome />
      </Container>
      <Category />
      <Container>
        <SearchBarProduct onSubmit={handleOnSubmitSearch} />
        <ProductRecommend />
        <AdsBanner />
        <NewArrivalItem />
      </Container>
      <PremiumSellerPricing />
    </Layout>
  );
}
