import { createTheme } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#072344",
    },
    secondary: {
      main: "#F2983D",
      contrastText: "#ffffff",
    },
    background: {
      default: "#ffffff",
    },
    onPrimary: { main: "#ffffff", color: "#ffffff" },
    paperGray: { main: "rgba(7, 35, 68, 0.05)", color: "#072344" },
    paperGray1: { main: "rgba(7, 35, 68, 0.1)", color: "#072344" },
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
    button: {
      textTransform: "none",
      fontWeight: 400,
    },
  },
  shadows: shadows.map((__, i) => (i === 0 ? "none" : shadows[1])),
  components: {
    MuiAppBar: {
      defaultProps: {
        color: "transparent",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

export default theme;
