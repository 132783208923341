import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const binanceApi = createApi({
  reducerPath: "binanceAPI",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.binance.com/api/v3" }),
  tagTypes: [],
  endpoints: (builder) => ({
    priceRate: builder.query({
      query: (symbol) => `/ticker/price?symbol=${symbol}`,
    }),
  }),
});

export const { usePriceRateQuery } = binanceApi;
